import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { BandUnit, UnitCard, UserOwnCard, UserUnit } from "p6m-p6u";
import { useT } from "@transifex/react";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import CardListingCardItem from "../../../basic/create/cardListingCardItem/CardListingCardItem";

import {
    UnitWrapper,
    UnitHeaderWrapper,
    SelectionWrapper,
    NameWrapper,
    CheckIcon,
    IconWrapper,
    CardListingWrapper,
    UnitNameWrapper,
    SelectedCardsCountWrapper,
    OpenClosedWrapper,
    StyledArrowButton,
    UserWarningWrapper,
    UnregisteredUserWarning,
    Checkbox,
    CenterDash,
} from "./StyledComponents";

export interface UnitAndCardsWrapperProps {
    unitContent: BandUnit | UserUnit;
    totalUnitCardsCount?: number;
    cardsList?: Array<UnitCard | UserOwnCard>;
    openDeleteModalFn: (cardId: string) => any;
    openDeleteUnitCardsFn: (cardsToDelete: Array<string>) => any;
    isUserOwnContent?: boolean;
    hideBottomLine?: boolean;
    isContentVisible?: boolean;
    shouldScrollToUnit?: boolean;
}

const UnitAndCardsWrapper: React.FC<UnitAndCardsWrapperProps> = (props) => {
    const {
        availableCards,
        vocabularyDrawerContent,
        loadCardsForUnit,
        removeCardsFromSelectedWords,
        addCardsToSelectedWords,
        maxAmountOfCardsSelectable,
        openWarningModal,
        loadUserUnitCards,
        setUnitsToPreloadIds,
    } = useTestCreationContext();
    const { userId, setIsDataBeingLoaded, isDataBeingLoaded, creatingMode } = useGeneralContext();
    const { unitContent, totalUnitCardsCount, cardsList } = props;

    const [isUnitContentVisible, setIsUnitContentVisible] = useState(!!props.isContentVisible);
    const [hasAllCardsSelected, setHasAllCardsSelected] = useState(false);
    const [selectedCardsAmount, setSelectedCardsAmount] = useState(0);
    const unitWrapperRef = useRef<HTMLDivElement>(null);

    const restCardsCount = (totalUnitCardsCount || 0) - (cardsList?.length || 0);
    const selectedCardIds = Object.keys(vocabularyDrawerContent);
    const selectedCardContent = Object.values(vocabularyDrawerContent);
    const shouldShowRestOfContentWarning = cardsList && !userId && restCardsCount > 0;

    const t = useT();
    const t_restOfContent = t("The other {restCards} cards from this Unit will be available after Registration", {
        restCards: restCardsCount,
        _tags: "CreateTest,UnitAndCardsWrapper",
    });
    const t_noCards = t("No cards available for this unit", { _tags: "CreateTest,UnitAndCardsWrapper" });
    const t_loading = t("Loading cards", { _tags: "CreateTest,UnitAndCardsWrapper" });

    const t_cantAddCards = t("It's not possible to add more than {maxAmountOfCards}", {
        maxAmountOfCards: maxAmountOfCardsSelectable,
        _tags: "CreateTest,UnitAndCardsWrapper",
    });
    const t_incompleteAdd = t(
        "It's not possible to add more than {maxAmountOfCards}, some cards were selected until the limit was reached.",
        { maxAmountOfCards: maxAmountOfCardsSelectable, _tags: "CreateTest,UnitAndCardsWrapper" }
    );
    const t_selectedCards = t("{selectedCardsAmount} cards selected", {
        selectedCardsAmount,
        _tags: "CreateTest,UnitAndCardsWrapper",
    });

    const checkIfAllCardsSelected = useCallback(() => {
        let areAllCardsInUnitSelected = true;
        let selectedCards = 0;
        if (cardsList && cardsList.length > 0 && selectedCardIds.length > 0) {
            for (let i = 0; i < cardsList.length; i++) {
                const cardId = String(cardsList[i].id);
                if (cardId) {
                    if (!selectedCardIds.includes(cardId)) {
                        areAllCardsInUnitSelected = false;
                    }
                    if (!areAllCardsInUnitSelected) {
                        break;
                    }
                }
            }
            setHasAllCardsSelected(areAllCardsInUnitSelected);

            for (let i = 0; i < cardsList.length; i++) {
                const cardId = String(cardsList[i].id);
                if (cardId) {
                    if (selectedCardIds.includes(cardId)) {
                        selectedCards++;
                    }
                }
            }
            setSelectedCardsAmount(selectedCards);
        } else {
            setHasAllCardsSelected(false);

            // Check if there's no cards because we're editing an exercise, and show the correct amount of cards.
            if (selectedCardIds.length > 0 && unitContent && unitContent.id) {
                const selectedCardsInUnit = selectedCardContent.filter((dw) => {
                    return dw.wordContent && unitContent.id && dw.wordContent.unitId === String(unitContent.id);
                });
                setSelectedCardsAmount(selectedCardsInUnit.length);
            } else {
                setSelectedCardsAmount(0);
            }
        }
    }, [cardsList, selectedCardIds, selectedCardContent, unitContent]);

    useEffect(() => {
        checkIfAllCardsSelected();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCardIds]);

    useEffect(() => {
        if (props.shouldScrollToUnit) {
            unitWrapperRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }, [props.shouldScrollToUnit]);

    if (!unitContent || !unitContent.id) {
        return <></>;
    }

    function toggleContentList() {
        const unitId = unitContent.id as string;
        // If currently is not visible, we load on open the cards for this unit.
        if (!isUnitContentVisible) {
            if (!availableCards.hasOwnProperty(unitId)) {
                setIsDataBeingLoaded(true);
                if (props.isUserOwnContent) {
                    loadUserUnitCards(unitId);
                } else {
                    loadCardsForUnit(unitId);
                }
            }
        }
        setIsUnitContentVisible(!isUnitContentVisible);
        setUnitsToPreloadIds((prevState) => {
            if (prevState.includes(unitId)) {
                return prevState.filter((id) => id !== unitId);
            } else {
                return [...prevState, unitId];
            }
        });
    }

    function handleCheckboxClick() {
        if (cardsList) {
            if (selectedCardsAmount > 0) {
                // Cant use array.map here without the {string|undefined)[] warning, even after filtering the undefined
                // So supressing the warning.
                // @ts-ignore

                let cardsToDelete: Array<string> = [];
                let needsWarningModal = false;

                cardsList.forEach((c) => {
                    if ("isUserCard" in c) {
                    }
                    if (c.id) {
                        cardsToDelete.push(c.id);
                        if (
                            !needsWarningModal &&
                            vocabularyDrawerContent[c.id] &&
                            vocabularyDrawerContent[c.id].exerciseIds &&
                            vocabularyDrawerContent[c.id].exerciseIds!.length > 0
                        ) {
                            needsWarningModal = true;
                        }
                    }
                });

                if (cardsToDelete.length > 0) {
                    if (needsWarningModal) {
                        props.openDeleteUnitCardsFn(cardsToDelete);
                    } else {
                        removeCardsFromSelectedWords(cardsToDelete);
                    }
                }
            } else {
                const addingResponse = addCardsToSelectedWords(cardsList);
                if (addingResponse !== "SUCCESS") {
                    openWarningModal(addingResponse === "CANT_ADD" ? t_cantAddCards : t_incompleteAdd);
                }
            }
        }
    }

    const renderUnitHeader = () => (
        <UnitHeaderWrapper
            onClick={isUnitContentVisible ? () => {} : toggleContentList}
            hideBottomLine={props.hideBottomLine}
        >
            {isUnitContentVisible && (
                <SelectionWrapper>
                    <Checkbox
                        className={hasAllCardsSelected ? "checked" : selectedCardsAmount > 0 ? "partial" : ""}
                        onClick={handleCheckboxClick}
                    >
                        {hasAllCardsSelected ? (
                            <CheckIcon name={"exercise-ok"} />
                        ) : selectedCardsAmount > 0 ? (
                            <CenterDash>-</CenterDash>
                        ) : (
                            ""
                        )}
                    </Checkbox>
                </SelectionWrapper>
            )}
            <NameWrapper>
                <UnitNameWrapper onClick={isUnitContentVisible ? toggleContentList : () => {}}>
                    {unitContent.name}
                </UnitNameWrapper>
                {(!isUnitContentVisible || creatingMode === "RECURRING_TASK") && selectedCardsAmount > 0 && (
                    <SelectedCardsCountWrapper>
                        <p>({t_selectedCards})</p>
                    </SelectedCardsCountWrapper>
                )}
            </NameWrapper>
            <IconWrapper>
                <OpenClosedWrapper>
                    <StyledArrowButton
                        onClick={isUnitContentVisible ? toggleContentList : () => {}}
                        direction={isUnitContentVisible ? "UP" : "DOWN"}
                    />
                </OpenClosedWrapper>
            </IconWrapper>
        </UnitHeaderWrapper>
    );

    const renderUnitContent = () => (
        <CardListingWrapper>
            {(!cardsList || cardsList.length === 0) && !isDataBeingLoaded && (
                <UserWarningWrapper>{t_noCards}</UserWarningWrapper>
            )}
            {(!cardsList || cardsList.length === 0) && isDataBeingLoaded && (
                <UserWarningWrapper>{t_loading}</UserWarningWrapper>
            )}
            {cardsList &&
                cardsList.length > 0 &&
                cardsList.map((c) => {
                    return (
                        <CardListingCardItem
                            card={c}
                            openModalCallback={() => {
                                props.openDeleteModalFn(c.id!);
                            }}
                            hideGapSentences={creatingMode === "RECURRING_TASK"}
                            isChecked={
                                vocabularyDrawerContent && !c.id && Object.keys(vocabularyDrawerContent).includes(c.id!)
                            }
                            key={c.id}
                        />
                    );
                })}

            {shouldShowRestOfContentWarning && (
                <UserWarningWrapper>
                    <UnregisteredUserWarning>{t_restOfContent}</UnregisteredUserWarning>
                </UserWarningWrapper>
            )}
        </CardListingWrapper>
    );

    return (
        <UnitWrapper ref={unitWrapperRef}>
            {renderUnitHeader()}
            {isUnitContentVisible && renderUnitContent()}
        </UnitWrapper>
    );
};

export default UnitAndCardsWrapper;
