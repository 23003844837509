// LIBRARIES
import React, { useEffect, useState } from "react";
import { useT } from "@transifex/react";
import { useHistory } from "react-router-dom";

//CONTEXT
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useBookContext } from "../../../context/BookContext";
import { useGeneralContext } from "../../../context/AppGeneralContext";

//COMPONENTS
import { CoverImage } from "p6-react-shared";
import CtasWrapper from "../../../wrapper/create/ctasWrapper/CtasWrapper";
import StyledButton from "../../../basic/styledButton/StyledButton";
import CreateInfoModal from "../../../basic/create/InfoModal/CreateInfoModal";
import UserContentCover from "../../../basic/userContentCover/UserContentCover";
import NoOwnContentModal from "../../../basic/create/noOwnContentModal/NoOwnContentModal";
import AccordionTab from "../../../basic/accordionTab/AccordionTab";
import ExerciseSheetTitle from "../../../basic/create/ExerciseSheetTitle/ExerciseSheetTitle";
//TYPES
import { AvailableUserOwnSubjectsInfo } from "p6m-p6u";

//UTILS
import { getDateTimeFromMilliseconds } from "../../../helpers/DateTime";

import {
    Wrapper,
    SourceOptionItem,
    SourceOptionIconWrapper,
    SourceOptionArrowWrapper,
    SourceOptionItemTitleWrapper,
    SourceOptionItemTitle,
    SourceOptionItemDesc,
    RecentlyUsedBooksWrapper,
    RecentlyUsedBook,
    RecentlyUsedBookCoverWrapper,
    RecentlyUsedBookTitle,
    RecentlyUsedBookSubtitle,
    RecentlyUsedBookDate,
    RecentlyUsedBookInfo,
    SourceChangeWarningWrapper,
    SourceChangeWarning,
    StyledArrowIcon,
    RecentBooksMainWrapper,
    ChooseNewContentTitleWrapper,
    ChooseNewContentTitle,
    StyledP6Icon,
    CenteredStyledButton,
    DisabledWhenEditingSpan,
    SourceWrapper,
    AlreadySelectedSourcesWrapper,
    BackIcon,
} from "./StyledComponents";
import DesignConstants from "../../../constants/DesignConstants";
import { StickyWrapper } from "../../../basic/accordionTab/StyledComponents";
import { SourceType } from "../../../enums/sources";

export interface CreateOrEditTestProps {}

type ModalOption = "NEW" | "RECENT";

const SelectVocabularySource: React.FC<CreateOrEditTestProps> = () => {
    const { userId } = useGeneralContext();
    const {
        vocabularyDrawerContent,
        testContent,
        selectedUserSubject,
        setSelectedUserSubject,
        selectedBookData,
        setShouldSubjectSelectionBeDisabled,
        loadUserOwnSubjectUUIDsInfoIntoState: loadOwnSubjectData,
        resetAppStatus,
        isTestBeingEdited,
        decideGoToOwnSubjectsAction,
        openWebAppInAddCardsMode,
        removeContentFromVocabularyDrawer,
        setIsTestSynced,
    } = useTestCreationContext();

    const { recentlyUsedBooks, loadUserRecentBooks, loadBookFromRecentBooks, setLastSourceSelected } = useBookContext();

    const t = useT();
    const t_recentBooks = t("Recently used Content", { _tags: "CreateTest,SelectVocabularySource" });
    const t_schoolbookTitle = t("Content from Schoolbook", { _tags: "CreateTest,SelectVocabularySource" });
    const t_schoolbookDesc = t(
        "You can select your schoolbook and the relevant vocabulary (sorted by Units and Page)",
        { _tags: "CreateTest,SelectVocabularySource" }
    );
    const t_ownVocabularyTitle = t("Self created content", { _tags: "CreateTest,SelectVocabularySource" });
    const t_ownVocabularyDesc = t("Use your own self created content", {
        _tags: "CreateTest,SelectVocabularySource",
    });
    const t_lastUsed = t("Last used:", { _tags: "CreateTest,SelectVocabularySource" });
    const t_back = t("Back", { _tags: "CreateTest,SelectVocabularySource" });
    const t_createExercise = t("Create Exercise", { _tags: "CreateTest,SelectVocabularySource" });
    const t_continue = t("Continue", { _tags: "CreateTest,SelectVocabularySource" });
    const t_bookChange = t("Changing the current book might delete the current selected content.", {
        _tags: "CreateTest,SelectVocabularySource",
    });
    const t_ownSubjectChange = t("Changing the current subject might delete the current selected content.", {
        _tags: "CreateTest,SelectVocabularySource",
    });
    const t_bookChange2 = t("Are you sure you want to continue?", { _tags: "CreateTest,SelectVocabularySource" });
    const t_ownSubjectChange2 = t("Are you sure you want to continue?", {
        _tags: "CreateTest,SelectVocabularySource",
    });
    const t_selectMore = t("Keep selecting", { _tags: "CreateTest,SelectVocabularySource" });
    const t_chooseNewContent = t("Choose new content", { _tags: "CreateTest,SelectVocabularySource" });
    const t_disabledEditing = t("Disabled when editing a test", { _tags: "CreateTest,SelectVocabularySource" });
    const t_OverallVocabulary = t("Overall Vocabulary", { _tags: "CreateTest,Tab" });
    const t_OverallExercises = t("Overall Exercises", { _tags: "CreateTest,Tab" });
    const t_selectVocabularySource = t("Which vocabulary do you want to use for your exercise sheet / your test?", {
        _tags: "CreateTest,Summary",
    });

    const [hideRecentBooks, setHideRecentBooks] = useState(true);

    const [isChangeSelectedOwnContentModalOpen, setIsChangeSelectedOwnContentModalOpen] = useState(false);
    const [isChangeSelectedBookModalOpen, setIsChangeSelectedBookModalOpen] = useState(false);
    const [bookToLoadFromRecent, setBookToLoadFromRecent] = useState("");
    const [contentToLoadIsOwnSubject, setContentToLoadIsOwnSubject] = useState(false);
    const [continueModalOption, setContinueModalOption] = useState<ModalOption>();

    const [isNoOwnContentModalOpen, setIsNoOwnContentModalOpen] = useState(false);
    const [needsSubjectsReload, setNeedsSubjectsReload] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (userId) {
            loadUserRecentBooks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openModalForSelectItem = (modalOption: ModalOption, subjectType: SourceType) => {
        const setterBySubjectType = {
            BOOK: () => setIsChangeSelectedBookModalOpen(true),
            OWN: () => setIsChangeSelectedOwnContentModalOpen(true),
        };
        setContinueModalOption(modalOption);
        setterBySubjectType[subjectType]();
    };

    const loadFromRecent = async (contentId: string, forceOpen: boolean = false, isOwnSubject = false) => {
        const itemType: SourceType = isOwnSubject ? SourceType.OWN : SourceType.BOOK;
        const selectedSubjectIdsPerType = {
            OWN: { id: selectedUserSubject?.id, loadData: async (id: string) => loadOwnSubjectData([id]) },
            BOOK: { id: selectedBookData?.band?.ID, loadData: async (id: string) => loadBookFromRecentBooks(id) },
        };

        const clickedOnRecentSubjectOrBookThatHasNotBeenSelectedYet =
            !forceOpen &&
            Object.keys(vocabularyDrawerContent).length !== 0 &&
            selectedSubjectIdsPerType[itemType].id &&
            `${selectedSubjectIdsPerType[itemType].id}` !== contentId;

        if (clickedOnRecentSubjectOrBookThatHasNotBeenSelectedYet) {
            openModalForSelectItem("RECENT", itemType);
            setBookToLoadFromRecent(contentId);
            setContentToLoadIsOwnSubject(isOwnSubject);
        } else {
            //if forceOpen (e.g. on modal continue) and/or ownSubject/book has been selected before run this:
            const response: AvailableUserOwnSubjectsInfo | void = await selectedSubjectIdsPerType[itemType].loadData(
                contentId
            );
            //response only exists on ownContent:
            if (response && response[contentId]) {
                setSelectedUserSubject(response[contentId]);
                if (`${selectedSubjectIdsPerType[itemType].id}` !== contentId) {
                    removeContentFromVocabularyDrawer(itemType);
                }
                history.push("/create/select-vocabulary");
            }
        }
    };

    function handleBackButton() {
        history.push("/create");
        resetAppStatus();
    }

    async function handleOwnSourceClick() {
        const action = await decideGoToOwnSubjectsAction(needsSubjectsReload);
        if (action === "CONTINUE") {
            if (
                hasAlreadySelectedOwnContent &&
                Object.values(vocabularyDrawerContent).filter(
                    (selectedItem) => selectedItem.wordContent && "isUserCard" in selectedItem.wordContent
                ).length
            ) {
                openModalForSelectItem("NEW", SourceType.OWN);
            } else {
                setShouldSubjectSelectionBeDisabled(false);

                setLastSourceSelected(SourceType.OWN);
                history.push("/create/select-vocabulary");
            }
        } else {
            setIsNoOwnContentModalOpen(true);
        }
    }

    function handleGoToWebAppClick() {
        openWebAppInAddCardsMode();
        setIsNoOwnContentModalOpen(false);
        setNeedsSubjectsReload(true);
    }

    const handleChangeModalContinue = async (subjectType: SourceType) => {
        setLastSourceSelected(subjectType);
        setIsTestSynced(false);

        if (continueModalOption === "RECENT") {
            await loadFromRecent(bookToLoadFromRecent, true, contentToLoadIsOwnSubject);
        } else {
            removeContentFromVocabularyDrawer(subjectType);
            const subjectTypeKeys = {
                OWN: {
                    setterToCall: () => setShouldSubjectSelectionBeDisabled(false),
                    url: "/create/select-vocabulary",
                },
                BOOK: { setterToCall: () => {}, url: "/create/select-book" },
            };
            const { setterToCall, url } = subjectTypeKeys[subjectType];
            setterToCall();
            history.push(url);
        }
    };

    const hasBooksToDisplay =
        Object.values(recentlyUsedBooks).filter((b) => !!b.articleId || !!b.ownSubjectId).length > 0;
    const hasAlreadySelectedABook = !!selectedBookData.band?.ID;
    const hasAlreadySelectedOwnContent = !!selectedUserSubject.id;

    const renderSelectedBook = () => {
        return (
            <SourceOptionItem
                key={selectedBookData.band?.ID}
                onClick={() => {
                    history.push("/create/select-vocabulary");
                }}
                className={"selectedBook"}
            >
                <BackIcon
                    name={"chevron-left"}
                    color={DesignConstants.COLORS.MID_GREY}
                />
                <SourceOptionIconWrapper>
                    <CoverImage
                        imageId={"https://www.phase-6.de" + selectedBookData.band!.Image}
                        size={"small"}
                    />
                </SourceOptionIconWrapper>
                <SourceOptionItemTitleWrapper>
                    <RecentlyUsedBookSubtitle>{t_selectMore}</RecentlyUsedBookSubtitle>
                    <RecentlyUsedBookTitle>{selectedBookData.band!.Name}</RecentlyUsedBookTitle>
                </SourceOptionItemTitleWrapper>
            </SourceOptionItem>
        );
    };

    const renderSelectedOwn = () => {
        return (
            <SourceOptionItem
                key={selectedUserSubject.id}
                onClick={() => {
                    setShouldSubjectSelectionBeDisabled(true);
                    setLastSourceSelected(SourceType.OWN);
                    history.push("/create/select-vocabulary");
                }}
                className={"selectedBook"}
            >
                <BackIcon
                    name={"chevron-left"}
                    color={DesignConstants.COLORS.MID_GREY}
                />
                <SourceOptionIconWrapper>
                    <UserContentCover
                        primaryLanguage={selectedUserSubject.primaryLang!}
                        secondaryLanguage={selectedUserSubject.secondaryLang || ""}
                        small
                    />
                </SourceOptionIconWrapper>
                <SourceOptionItemTitleWrapper>
                    <RecentlyUsedBookSubtitle>{t_selectMore}</RecentlyUsedBookSubtitle>
                    <RecentlyUsedBookTitle>{selectedUserSubject.name}</RecentlyUsedBookTitle>
                </SourceOptionItemTitleWrapper>
            </SourceOptionItem>
        );
    };

    const renderRecentBooksHeader = () => {
        return (
            <SourceOptionItem
                onClick={() => {
                    setHideRecentBooks((val) => !val);
                }}
                className={!hideRecentBooks ? "orange" : ""}
                noBorder
            >
                <SourceOptionIconWrapper>
                    <StyledP6Icon name={"clock"} />
                </SourceOptionIconWrapper>
                <SourceOptionItemTitleWrapper>
                    <SourceOptionItemTitle>
                        {t_recentBooks}
                        {isTestBeingEdited && (
                            <>
                                <br />
                                <DisabledWhenEditingSpan>{t_disabledEditing}</DisabledWhenEditingSpan>
                            </>
                        )}
                    </SourceOptionItemTitle>
                </SourceOptionItemTitleWrapper>
                <SourceOptionArrowWrapper>
                    <StyledArrowIcon
                        onClick={() => {}}
                        direction={hideRecentBooks ? "RIGHT" : "BOTTOM"}
                    />
                </SourceOptionArrowWrapper>
            </SourceOptionItem>
        );
    };

    const renderRecentBooksContent = () => {
        return (
            <RecentlyUsedBooksWrapper amountRecent={Object.values(recentlyUsedBooks).length}>
                {Object.values(recentlyUsedBooks)
                    .sort((a, b) => (b.date || 0) - (a.date || 0))
                    .map((book) => {
                        if (!book.articleId && !book.ownSubjectId) {
                            return false;
                        }
                        return (
                            <RecentlyUsedBook
                                amountRecent={Object.values(recentlyUsedBooks).length}
                                key={book.articleId || book.ownSubjectId}
                                onClick={async () => {
                                    setLastSourceSelected(SourceType.BOOK);
                                    await loadFromRecent(
                                        (book.articleId || book.ownSubjectId)!,
                                        false,
                                        !!book.ownSubjectId
                                    );
                                }}
                            >
                                <RecentlyUsedBookCoverWrapper>
                                    {book.imagePath && (
                                        <CoverImage
                                            imageId={"https://www.phase-6.de" + book.imagePath}
                                            size={"small"}
                                        />
                                    )}
                                    {book.primaryLang && (
                                        <UserContentCover
                                            primaryLanguage={book.primaryLang}
                                            secondaryLanguage={book.secondaryLang || ""}
                                            small
                                        />
                                    )}
                                </RecentlyUsedBookCoverWrapper>
                                <RecentlyUsedBookInfo>
                                    <RecentlyUsedBookTitle>{book.name}</RecentlyUsedBookTitle>
                                    <RecentlyUsedBookDate>
                                        {t_lastUsed}
                                        <br />
                                        {book.date && getDateTimeFromMilliseconds(book.date)}
                                    </RecentlyUsedBookDate>
                                </RecentlyUsedBookInfo>
                            </RecentlyUsedBook>
                        );
                    })}
            </RecentlyUsedBooksWrapper>
        );
    };

    const renderRecentBooks = () => {
        return (
            <RecentBooksMainWrapper className={`recentBooks ${isTestBeingEdited ? "disabled" : ""}`}>
                {renderRecentBooksHeader()}
                {!hideRecentBooks && renderRecentBooksContent()}
            </RecentBooksMainWrapper>
        );
    };

    const renderSelectVocabularyFromBook = () => {
        return (
            <SourceOptionItem
                className={`selectBook ${isTestBeingEdited ? "disabled" : ""}`}
                onClick={() => {
                    if (hasAlreadySelectedABook && Object.keys(vocabularyDrawerContent).length > 0) {
                        openModalForSelectItem("NEW", SourceType.BOOK);
                    } else {
                        setLastSourceSelected(SourceType.BOOK);
                        history.push("/create/select-book");
                    }
                }}
            >
                <SourceOptionIconWrapper>
                    <StyledP6Icon name={"library"} />
                </SourceOptionIconWrapper>
                <SourceOptionItemTitleWrapper>
                    <SourceOptionItemTitle>
                        {t_schoolbookTitle}
                        {isTestBeingEdited && (
                            <>
                                <br />
                                <DisabledWhenEditingSpan>{t_disabledEditing}</DisabledWhenEditingSpan>
                            </>
                        )}
                    </SourceOptionItemTitle>
                    {!hasAlreadySelectedABook && <SourceOptionItemDesc>{t_schoolbookDesc}</SourceOptionItemDesc>}
                </SourceOptionItemTitleWrapper>
                <SourceOptionArrowWrapper>
                    <StyledArrowIcon
                        onClick={() => {}}
                        direction={"RIGHT"}
                    />
                </SourceOptionArrowWrapper>
            </SourceOptionItem>
        );
    };

    const renderSelectOwnVocabulary = () => {
        return (
            <SourceOptionItem
                className={`ownSource ${isTestBeingEdited ? "disabled" : ""}`}
                onClick={handleOwnSourceClick}
            >
                <SourceOptionIconWrapper>
                    <StyledP6Icon name={"cards-2"} />
                </SourceOptionIconWrapper>
                <SourceOptionItemTitleWrapper>
                    <SourceOptionItemTitle>
                        {t_ownVocabularyTitle}
                        {isTestBeingEdited && (
                            <>
                                <br />
                                <DisabledWhenEditingSpan>{t_disabledEditing}</DisabledWhenEditingSpan>
                            </>
                        )}
                    </SourceOptionItemTitle>
                    {!hasAlreadySelectedABook && <SourceOptionItemDesc>{t_ownVocabularyDesc}</SourceOptionItemDesc>}
                </SourceOptionItemTitleWrapper>
                <SourceOptionArrowWrapper>
                    <StyledArrowIcon
                        onClick={() => {}}
                        direction={"RIGHT"}
                    />
                </SourceOptionArrowWrapper>
            </SourceOptionItem>
        );
    };

    const renderAccordionTabVocabularyContent = () => {
        return (
            <div>
                {(hasAlreadySelectedABook || hasAlreadySelectedOwnContent) && (
                    <AlreadySelectedSourcesWrapper>
                        {hasAlreadySelectedABook && renderSelectedBook()}
                        {hasAlreadySelectedOwnContent && renderSelectedOwn()}
                    </AlreadySelectedSourcesWrapper>
                )}
                {(hasAlreadySelectedABook || hasAlreadySelectedOwnContent) && (
                    <ChooseNewContentTitleWrapper>
                        <ChooseNewContentTitle>{t_chooseNewContent}</ChooseNewContentTitle>
                    </ChooseNewContentTitleWrapper>
                )}
                <SourceWrapper>
                    {hasBooksToDisplay && renderRecentBooks()}
                    {renderSelectVocabularyFromBook()}
                    {renderSelectOwnVocabulary()}
                </SourceWrapper>
            </div>
        );
    };

    const renderAccordionTabVocabulary = () => {
        const usedVocabulary =
            Object.values(vocabularyDrawerContent).filter((word) => word.exerciseIds && word.exerciseIds.length > 0)
                .length ?? 0;
        const availableVocabulary = Object.values(vocabularyDrawerContent).length || 0;

        return (
            <AccordionTab
                mainIconName={"vocabulary-list-unset"}
                title={t_OverallVocabulary}
                counterValue={"(" + usedVocabulary + " / " + availableVocabulary + ")"}
                showAccordionTabDetails
                isActive
            >
                {t_selectVocabularySource}
                {renderAccordionTabVocabularyContent()}
            </AccordionTab>
        );
    };

    const renderAccordionTabExercises = () => {
        if (testContent.length > 0) {
            const amountExercises = testContent.length;
            return (
                <StickyWrapper>
                    <AccordionTab
                        mainIconName={"clipboard"}
                        title={t_OverallExercises}
                        counterValue={"(" + amountExercises + ")"}
                        onClickEdit={() => {
                            setLastSourceSelected(undefined);
                            history.push("/create/summary");
                        }}
                    >
                        <></>
                    </AccordionTab>
                </StickyWrapper>
            );
        } else {
            return <div />;
        }
    };

    const renderChangeBookModal = () => {
        return (
            <CreateInfoModal
                isOpen={isChangeSelectedBookModalOpen}
                onRequestClose={() => {
                    setIsChangeSelectedBookModalOpen(false);
                }}
                continueAction={async () => handleChangeModalContinue(SourceType.BOOK)}
                continueText={t_continue}
                cancelAction={() => {
                    setIsChangeSelectedBookModalOpen(false);
                }}
                compact
            >
                <SourceChangeWarningWrapper>
                    <SourceChangeWarning>{t_bookChange}</SourceChangeWarning>
                    <br />
                    <SourceChangeWarning>{t_bookChange2}</SourceChangeWarning>
                </SourceChangeWarningWrapper>
            </CreateInfoModal>
        );
    };

    const renderChangeOwnModal = () => {
        return (
            <CreateInfoModal
                isOpen={isChangeSelectedOwnContentModalOpen}
                onRequestClose={() => {
                    setIsChangeSelectedOwnContentModalOpen(false);
                }}
                continueAction={async () => handleChangeModalContinue(SourceType.OWN)}
                continueText={t_continue}
                cancelAction={() => {
                    setIsChangeSelectedOwnContentModalOpen(false);
                }}
                compact
            >
                <SourceChangeWarningWrapper>
                    <SourceChangeWarning>{t_ownSubjectChange}</SourceChangeWarning>
                    <br />
                    <SourceChangeWarning>{t_ownSubjectChange2}</SourceChangeWarning>
                </SourceChangeWarningWrapper>
            </CreateInfoModal>
        );
    };

    const renderFooter = () => {
        return (
            <CtasWrapper showScrollToTop>
                {!testContent.length && (
                    <StyledButton
                        onClick={handleBackButton}
                        arrowDirection={"LEFT"}
                        buttonStyle={"BLANK"}
                    >
                        {t_back}
                    </StyledButton>
                )}
                <CenteredStyledButton
                    onClick={() => {
                        history.push("/create/summary");
                    }}
                    arrowDirection={"RIGHT"}
                    buttonStyle={"PRIMARY"}
                    disabled={Object.keys(vocabularyDrawerContent).length < 3}
                >
                    {t_createExercise}
                </CenteredStyledButton>
            </CtasWrapper>
        );
    };

    const renderNoOwnContentModal = () => {
        return (
            <NoOwnContentModal
                isOpen={isNoOwnContentModalOpen}
                closeModalCallback={() => {
                    setIsNoOwnContentModalOpen(false);
                }}
                continueCallback={handleGoToWebAppClick}
                continueButtonName={t_ownVocabularyTitle}
            />
        );
    };

    return (
        <Wrapper>
            <ExerciseSheetTitle />
            {renderAccordionTabVocabulary()}
            {renderAccordionTabExercises()}
            {renderChangeBookModal()}
            {renderChangeOwnModal()}
            {renderFooter()}
            {renderNoOwnContentModal()}
        </Wrapper>
    );
};

export default SelectVocabularySource;
