import * as React from "react";
import { useTestCreationContext } from "../../../../context/TestCreationContext";
import { useT } from "@transifex/react";
import { Exercises, ExerciseTypes } from "../../../../enums/exercises";
import SelectExerciseVocabularyContent from "./selectExerciseVocabularyContent/SelectExerciseVocabularyContent";

import {
    Wrapper,
    ToggleSelectVocabularyButton,
    SelectExerciseVocabularyHeaderTitle,
    StyledIcon,
    StyledInfoIcon,
    NoMoreVocabularyWrapper,
    TextWrapper,
    NoMoreVocabularyInfo,
    NoMoreVocabularyHint,
    NoMoreVocabularyHintText,
    NoMoreConnectWordsWrapper,
    InfoIconWrapper,
    ConnectInfoWrapper,
    ConnectInfoText,
} from "./StyledComponents";

export interface SelectExerciseVocabularyProps {
    onClose: () => void;
}

const SelectExerciseVocabulary: React.FC<SelectExerciseVocabularyProps> = ({ onClose }) => {
    const { vocabularyDrawerContent, currentExerciseModel } = useTestCreationContext();

    const t = useT();
    const t_addWords = t("Add words", { _tags: "CreateTest,SelectExerciseVocabulary" });
    const t_entireSelectedVocabularyUsed = t("You have already used the entire selected vocabulary.", {
        _tags: "CreateTest,SelectExerciseVocabulary",
    });
    const t_hint = t("Tip", {
        _tags: "CreateTest,SelectExerciseVocabulary",
    });
    const t_selectMoreVocabulary = t("Would you like to add more vocabulary from the selected book?", {
        _tags: "CreateTest,SelectExerciseVocabulary",
    });
    const t_chooseMoreVocabulary = t("Choose those from above under 'Choose Vocabulary'.", {
        _tags: "CreateTest,SelectExerciseVocabulary",
    });
    const t_maxFiveWords = t("You can only select a maximum of 5 words for this exercise type.", {
        _tags: "CreateTest,SelectExerciseVocabulary",
    });

    const isConnectExercise = currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.CONNECT_WORDS);
    const isGapSentenceExercise = currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FILL_GAP);
    const isLimitedExercise = isConnectExercise || isGapSentenceExercise;

    const max5WordsReached = currentExerciseModel.wordsInProgress && currentExerciseModel.wordsInProgress.length > 4;
    const currentAmountOfWords = currentExerciseModel.wordsInProgress ? currentExerciseModel.wordsInProgress.length : 0;

    function _filterVocabulary() {
        let availableVocabulary = [];
        for (const [key, value] of Object.entries(vocabularyDrawerContent)) {
            if (
                !currentExerciseModel.wordsInProgress ||
                currentExerciseModel.wordsInProgress?.findIndex((word) => ("id" in word ? word.id : "") === key) === -1
            ) {
                availableVocabulary.push(value);
            }
        }
        if (currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FILL_GAP)) {
            availableVocabulary = availableVocabulary.filter(
                (w) => w.wordContent && "gap_sentence" in w.wordContent && w.wordContent?.gap_sentence
            );
        }
        return availableVocabulary;
    }

    const availableVocabulary = _filterVocabulary();

    const renderToggleAddWordsButton = () => {
        return (
            <ToggleSelectVocabularyButton onClick={onClose}>
                <StyledIcon name={"chevron-up"} />
                <SelectExerciseVocabularyHeaderTitle>{t_addWords}</SelectExerciseVocabularyHeaderTitle>
            </ToggleSelectVocabularyButton>
        );
    };

    const renderMax5Hint = () => {
        return (
            <NoMoreConnectWordsWrapper>
                <ConnectInfoWrapper>
                    <StyledInfoIcon name={"info"} />
                </ConnectInfoWrapper>
                <ConnectInfoText>{t_maxFiveWords}</ConnectInfoText>
            </NoMoreConnectWordsWrapper>
        );
    };

    const renderNoMoreVocabulary = () => {
        return (
            <>
                <TextWrapper>
                    <InfoIconWrapper>
                        <StyledInfoIcon name={"info"} />
                    </InfoIconWrapper>
                    <NoMoreVocabularyInfo>{t_entireSelectedVocabularyUsed}</NoMoreVocabularyInfo>
                </TextWrapper>
                {Object.keys(vocabularyDrawerContent).length < 50 && (
                    <>
                        <TextWrapper>
                            <NoMoreVocabularyHint>{`${t_hint}:`}</NoMoreVocabularyHint>
                            <NoMoreVocabularyHintText>{t_selectMoreVocabulary}</NoMoreVocabularyHintText>
                        </TextWrapper>
                        <TextWrapper>
                            <NoMoreVocabularyHintText>{t_chooseMoreVocabulary}</NoMoreVocabularyHintText>
                        </TextWrapper>
                    </>
                )}
            </>
        );
    };

    return (
        <Wrapper>
            {renderToggleAddWordsButton()}
            {isLimitedExercise && renderMax5Hint()}
            {availableVocabulary.length > 0 ? (
                <SelectExerciseVocabularyContent
                    availableVocabulary={availableVocabulary}
                    disabled={isLimitedExercise && max5WordsReached}
                    selectAllDisabled={isLimitedExercise && currentAmountOfWords + availableVocabulary.length > 5}
                    isLimitedExercise={isLimitedExercise}
                    currentAmountOfWords={currentAmountOfWords}
                />
            ) : (
                (!isLimitedExercise || !max5WordsReached) && (
                    <NoMoreVocabularyWrapper>{renderNoMoreVocabulary()}</NoMoreVocabularyWrapper>
                )
            )}
        </Wrapper>
    );
};

export default SelectExerciseVocabulary;
