import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";

export const CardWrapper = styled.div`
    width: 100%;
    padding: ${DesignConstants.SPACES.XS} 0;
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    align-self: center;
    background: white;
    display: flex;
    cursor: pointer;
`;

export const CheckboxWrapper = styled.div`
    margin: 0;
    width: ${DesignConstants.FONT_SIZES.XXL};
    display: flex;
    align-items: center;
    justify-content: space-around;

    &.half-opacity {
        opacity: ${DesignConstants.OPACITY.SIX};
    }
`;

export const QuestionWrapper = styled.div`
    flex: 1;
    padding: 0 ${DesignConstants.SPACES.XXS};
    font-size: ${DesignConstants.FONT_SIZES.M};
    display: flex;
    align-items: center;
`;

export const AnswerWrapper = styled.div`
    flex: 1;
    padding: 0 ${DesignConstants.SPACES.XXS};
    font-size: ${DesignConstants.FONT_SIZES.M};
    display: flex;
    align-items: center;
`;

export const QuestionAnswerWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    &.half-opacity {
        opacity: ${DesignConstants.OPACITY.SIX};
    }
`;

export const TextSpan = styled.span`
    font-size: inherit;

    &.checked {
        color: ${DesignConstants.COLORS.P6_ORANGE};
    }
`;

export const Checkbox = styled.div`
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 0.2rem;
    border: solid 1px ${DesignConstants.COLORS.MID_GREY};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: normal;
    text-transform: lowercase;

    &.checked {
        border-color: ${DesignConstants.COLORS.P6_ORANGE};
        background: ${DesignConstants.COLORS.P6_ORANGE};
        color: white;
    }
`;

export const GapSentenceWrapper = styled.div`
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.XXS} 0;
    width: 100%;
    font-size: ${DesignConstants.FONT_SIZES.S};
`;

export const SmallP6Icon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};

    &.orange {
        color: ${DesignConstants.COLORS.P6_ORANGE};
    }
`;
export const CheckIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;

export const CardAlreadyInUseIconWrapper = styled.div`
    width: ${DesignConstants.FONT_SIZES.XXL};
    height: ${DesignConstants.FONT_SIZES.XXL};
`;
