// LIBRARIES
import React, { useEffect, useState } from "react";
import { useT } from "@transifex/react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useBookContext } from "../../../context/BookContext";
import { useHistory } from "react-router-dom";
import StyledButton from "../../../basic/styledButton/StyledButton";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { P6uBand, P6uPublisherBook } from "p6m-p6u";
import CtasWrapper from "../../../wrapper/create/ctasWrapper/CtasWrapper";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import { logEvent, logEventWithProps } from "../../../logging/Logger";
import { postRequest } from "../../../helpers/networkHelper";
import NoOwnContentModal from "../../../basic/create/noOwnContentModal/NoOwnContentModal";
import { AxiosResponse } from "axios";
import AccordionTab from "../../../basic/accordionTab/AccordionTab";
import SelectBookRT from "../selectBookRT/SelectBookRT";
import ExerciseSheetTitle from "../../../basic/create/ExerciseSheetTitle/ExerciseSheetTitle";
import {
    Wrapper,
    MiddleContentWrapper,
    SelectsWrapper,
    SelectBookBtn,
    SmallSelectWrapper,
    BookSearchSelectWrapper,
    BookInfoWrapper,
    BookImageWrapper,
    BookNameWrapper,
    BookName,
    SelectedContentWrapper,
    AdditionalInfoWrapper,
    StyledCoverImage,
    TestToText,
} from "./StyledComponents";
import InformationBanner from "../../../basic/informationBanner/InformationBanner";
import { StickyWrapper } from "../../../basic/accordionTab/StyledComponents";
import { SourceType } from "../../../enums/sources";

export interface SelectBookProps {}

const SelectBook: React.FC<SelectBookProps> = (props) => {
    const {
        vocabularyDrawerContent,
        openWarningModal,
        selectedBookData,
        userTests,
        decideGoToOwnSubjectsAction,
        setSelectedBookData,
        canLogEvent,
        removeContentFromVocabularyDrawer,
        openWebAppInAddCardsMode,
        testContent,
        setShouldSubjectSelectionBeDisabled,
    } = useTestCreationContext();

    const {
        loadAvailableSubjects,
        availableSubjects,
        loadPublisherBooksForSubject,
        availablePublishersAndBooks,
        loadBandsForBook,
        availableBands,
        setAvailablePublishersAndBooks,
        setAvailableBands,
        setLastSourceSelected,
    } = useBookContext();

    const { creatingMode, userInfo, setUserInfo } = useGeneralContext();

    const t = useT();
    const t_selectBook = t("Select Book", { _tags: "CreateTest,SelectBook" });
    const t_back = t("Back", { _tags: "CreateTest,SelectBook" });
    const t_useOwnContentInfo = t("Use your own vocabulary in the meantime.", { _tags: "CreateTest,SelectBook" });
    const t_ownContentAnonymous = t("After registering, you can also use your own vocabulary", {
        _tags: "CreateTest,SelectBook",
    });
    const t_searchBook = t("Search Book", { _tags: "CreateTest,SelectBook" });
    const t_noResults = t("No Results", { _tags: "CreateTest,SelectBook" });
    const t_pleaseSelectLanguage = t("Please select first a language", { _tags: "CreateTest,SelectBook" });
    const t_notAllBookTitle = t("This function is currently available for almost all books", {
        _tags: "CreateTest,SelectBook",
    });
    const t_notAvailableBook = t(
        "Can't find your book? We will let you know through our teachers Newsletter when we have new content available.",
        { _tags: "CreateTest,SelectBook" }
    );
    const t_notAvailableBookNotRegistered = t(
        "Can't find your book? We will let you know through our teachers Newsletter when we have new content available. The subscription to the Newsletter is only possible after registration or login.",
        { _tags: "CreateTest,SelectBook" }
    );
    const t_signUp = t("Sign up now!", { _tags: "CreateTest,SelectBook" });
    const t_testTo = t("Test to", { _tags: "CreateTest,SelectVocabularySource" });
    const t_selectLanguage = t("Select a Language", { _tags: "CreateTest,SelectVocabularySource" });
    const t_selectBand = t("Select a Band", { _tags: "CreateTest,SelectVocabularySource" });
    const t_errorNewsletter = t("Error while subscribing to the Newsletter, please try later", {
        _tags: "CreateTest,SelectVocabularySource",
    });
    const t_unconfirmedNewsletter = t(
        "A step is missing! To complete your subscription to the newsletter, please check your email!",
        { _tags: "CreateTest,SelectVocabularySource" }
    );
    const t_continue = t("Continue", { _tags: "CreateTest,SelectVocabularySource" });

    const t_OverallVocabulary = t("Overall Vocabulary", { _tags: "CreateTest,Tab" });
    const t_OverallExercises = t("Overall Exercises", { _tags: "CreateTest,Tab" });
    const t_selectBookInstruction = t("Please select a book.", {
        _tags: "CreateTest,Summary",
    });

    const [selectedSubject, setSelectedSubject] = useState("");
    const [selectedPublisherBook, setSelectedPublisherBook] = useState("");
    const [selectedBand, setSelectedBand] = useState("");

    const [selectedPublisherBookInfo, setSelectedPublisherBookInfo] = useState<P6uPublisherBook>({});
    const [selectedBandInfo, setSelectedBandInfo] = useState<P6uBand>({});

    const [searchBookPlaceholder, setSearchBookPlaceholder] = useState("");
    const [selectBandPlaceholder, setSelectBandPlaceholder] = useState("");
    const [shouldInitPublisherBook, setShouldInitPublisherBook] = useState(false);
    const [shouldInitBand, setShouldInitBand] = useState(false);

    const [isNoOwnContentModalOpen, setIsNoOwnContentModalOpen] = useState(false);
    const [needsSubjectsReload, setNeedsSubjectsReload] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (availableSubjects.length === 0) {
            loadAvailableSubjects();
        }

        // Init when selected a book already
        if (selectedBookData) {
            if (selectedBookData.subject?.ID) {
                setSelectedSubject(selectedBookData.subject.ID);

                if (selectedBookData.publisherBook?.CombinedID) {
                    setShouldInitPublisherBook(true);

                    if (selectedBookData.band?.UUID) {
                        setShouldInitBand(true);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (shouldInitPublisherBook && availablePublishersAndBooks.length > 0) {
            setSelectedPublisherBook(selectedBookData.publisherBook!.CombinedID!);
            setSelectedPublisherBookInfo(selectedBookData.publisherBook!);
            setShouldInitPublisherBook(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldInitPublisherBook, availablePublishersAndBooks]);

    useEffect(() => {
        if (shouldInitBand && availableBands.length > 0) {
            setSelectedBand(selectedBookData.band!.UUID!);
            setSelectedBandInfo(selectedBookData.band!);
            setShouldInitBand(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldInitBand, availableBands]);

    useEffect(() => {
        if (selectedSubject && selectedSubject !== "") {
            loadPublisherBooksForSubject(selectedSubject);
            setSearchBookPlaceholder(t_searchBook);
        } else {
            setSearchBookPlaceholder("");
        }
        setSelectBandPlaceholder("");
        setAvailablePublishersAndBooks([]);
        setAvailableBands([]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSubject]);

    useEffect(() => {
        if (selectedSubject && selectedPublisherBook && selectedPublisherBook !== "") {
            loadBandsForBook(selectedSubject, selectedPublisherBook);
            setSelectBandPlaceholder(t_selectBand);
        } else {
            setAvailablePublishersAndBooks([]);
            setSelectBandPlaceholder("");
        }
        setAvailableBands([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPublisherBook]);

    const updateSubject = (selectedValue: any) => {
        setSelectedSubject(selectedValue as string);
        setSelectedPublisherBook("");
        setSelectedBand("");
        setSelectedBandInfo({});
        setSelectedPublisherBookInfo({});
    };

    const updatePublisherBook = (selectedValue: any) => {
        setSelectedPublisherBook(selectedValue as string);
        setSelectedBand("");
        setSelectedBandInfo({});
        setSelectedPublisherBookInfo(availablePublishersAndBooks.find((pb) => pb.CombinedID === selectedValue));
    };

    const updateBand = (selectedValue: any) => {
        setSelectedBand(selectedValue as string);
        setSelectedBandInfo(availableBands.find((b) => b.UUID === selectedValue));
    };

    function continueToVocabularySelection() {
        if (selectedBookData && selectedBookData.band && selectedBand !== selectedBookData.band.UUID) {
            if (Object.keys(vocabularyDrawerContent).length > 0) {
                removeContentFromVocabularyDrawer("BOOK");
            }
        }

        setSelectedBookData({
            band: selectedBandInfo,
            publisherBook: selectedPublisherBookInfo,
            subject: availableSubjects.find((s) => s.ID === selectedSubject),
        });
        history.push("/create/select-vocabulary");
    }

    function handleBackButton() {
        history.goBack();
    }

    async function signupToNewsletter() {
        try {
            const subscriptionResponse: AxiosResponse<{ isUnconfirmed: boolean; needsEvent: boolean }> =
                await postRequest("aea/user/newsletter/");

            let subscriptionStatus;

            if (subscriptionResponse.data.hasOwnProperty("error")) {
                openWarningModal(t_errorNewsletter);
                subscriptionStatus = "Error";
            } else {
                if (subscriptionResponse.data.isUnconfirmed) {
                    openWarningModal(t_unconfirmedNewsletter);
                    subscriptionStatus = "unconfirmed";
                } else {
                    openWarningModal("Sie haben unseren Newsletter erfolgreich abonniert.");
                    setUserInfo({ ...userInfo, hasNewsletterSubscription: true });
                    subscriptionStatus = "confirmed";
                }
            }

            if (canLogEvent()) {
                logEventWithProps("VTG - Subscribe to newsletter", {
                    subscriptionStatus,
                });

                if (subscriptionResponse.data.needsEvent) {
                    logEvent("newsletterSubscription");
                }
            }
        } catch (e) {
            openWarningModal(t_errorNewsletter);
        }
    }

    async function handleContinueOwnSubjectsClick() {
        let action = await decideGoToOwnSubjectsAction(needsSubjectsReload);
        if (action === "CONTINUE") {
            setShouldSubjectSelectionBeDisabled(false);
            setLastSourceSelected(SourceType.OWN);
            history.push("/create/select-vocabulary");
        } else {
            setIsNoOwnContentModalOpen(true);
        }
    }

    function handleGoToWebAppClick() {
        openWebAppInAddCardsMode();
        setIsNoOwnContentModalOpen(false);
        setNeedsSubjectsReload(true);
    }

    const renderSelection = () => {
        return (
            <SelectsWrapper>
                <SmallSelectWrapper>
                    <SelectSearch
                        options={availableSubjects.map((s) => {
                            return { name: s.Name, value: s.ID };
                        })}
                        search
                        filterOptions={fuzzySearch}
                        emptyMessage={t_noResults}
                        placeholder={t_selectLanguage}
                        value={selectedSubject}
                        onChange={updateSubject}
                    />
                </SmallSelectWrapper>
                <BookSearchSelectWrapper>
                    <SelectSearch
                        options={availablePublishersAndBooks.map((s) => {
                            return { name: s.DisplayName, value: s.CombinedID };
                        })}
                        search
                        filterOptions={fuzzySearch}
                        placeholder={searchBookPlaceholder}
                        value={selectedPublisherBook}
                        onChange={updatePublisherBook}
                        disabled={!selectedSubject}
                        emptyMessage={availablePublishersAndBooks.length > 0 ? t_noResults : t_pleaseSelectLanguage}
                    />
                </BookSearchSelectWrapper>
                <SmallSelectWrapper>
                    <SelectSearch
                        options={availableBands.map((s) => {
                            return { name: s.ShortName || s.Name, value: s.UUID };
                        })}
                        search
                        filterOptions={fuzzySearch}
                        emptyMessage={t_noResults}
                        disabled={!selectedPublisherBook}
                        value={selectedBand}
                        onChange={updateBand}
                        placeholder={selectBandPlaceholder}
                    />
                </SmallSelectWrapper>
            </SelectsWrapper>
        );
    };

    const renderSelectedBook = () => {
        return (
            <SelectedContentWrapper>
                {selectedBandInfo && selectedBandInfo.UUID && (
                    <BookInfoWrapper>
                        <BookImageWrapper>
                            <StyledCoverImage imageId={"https://www.phase-6.de" + selectedBandInfo.Image} />
                        </BookImageWrapper>
                        <BookNameWrapper>
                            <TestToText>{t_testTo}</TestToText>
                            <BookName>
                                {selectedPublisherBookInfo.BookName} ({selectedPublisherBookInfo.PublisherName}) (
                                {selectedBandInfo.ShortName})
                            </BookName>
                        </BookNameWrapper>
                        <SelectBookBtn
                            icon={"library"}
                            iconColor={"white"}
                            iconPosition={"LEFT"}
                            onClick={continueToVocabularySelection}
                            arrowDirection={"RIGHT"}
                            buttonStyle={"PRIMARY"}
                        >
                            {t_selectBook}
                        </SelectBookBtn>
                    </BookInfoWrapper>
                )}
            </SelectedContentWrapper>
        );
    };

    const renderNewsletterBanner = () => {
        return (
            <InformationBanner
                iconName={"info"}
                title={t_notAllBookTitle}
                isAnonymous={!!userInfo.isAnonymousUser}
                anonymousText={t_notAvailableBookNotRegistered}
                registeredText={t_notAvailableBook}
                showButton={!userInfo.isAnonymousUser && !userInfo.hasNewsletterSubscription}
                onButtonPress={signupToNewsletter}
                buttonText={t_signUp}
            />
        );
    };

    const renderOwnContentBanner = () => {
        return (
            <InformationBanner
                iconName={"cards-2"}
                isAnonymous={!!userInfo.isAnonymousUser}
                anonymousText={t_ownContentAnonymous}
                registeredText={t_useOwnContentInfo}
                showButton={!userInfo.isAnonymousUser}
                onButtonPress={handleContinueOwnSubjectsClick}
                buttonText={t_continue}
            />
        );
    };

    const renderAccordionTabVocabularyContent = () => {
        return (
            <div>
                <MiddleContentWrapper>
                    {renderSelection()}
                    {renderSelectedBook()}
                </MiddleContentWrapper>
                <AdditionalInfoWrapper className={userInfo.isAnonymousUser ? "bottom-zero" : ""}>
                    {renderNewsletterBanner()}
                    {userTests.length === 0 && creatingMode === "TEST" && renderOwnContentBanner()}
                </AdditionalInfoWrapper>
            </div>
        );
    };

    const renderAccordionTabVocabulary = () => {
        const usedVocabulary =
            Object.values(vocabularyDrawerContent).filter((word) => word.exerciseIds && word.exerciseIds.length > 0)
                .length ?? 0;
        const availableVocabulary = Object.values(vocabularyDrawerContent).length || 0;

        return (
            <AccordionTab
                mainIconName={"vocabulary-list-unset"}
                title={t_OverallVocabulary}
                counterValue={"(" + usedVocabulary + " / " + availableVocabulary + ")"}
                showAccordionTabDetails
                isActive
            >
                {t_selectBookInstruction}
                {renderAccordionTabVocabularyContent()}
            </AccordionTab>
        );
    };

    const renderAccordionTabExercises = () => {
        if (testContent.length > 0) {
            const amountExercises = testContent.length;

            return (
                <StickyWrapper>
                    <AccordionTab
                        mainIconName={"clipboard"}
                        title={t_OverallExercises}
                        counterValue={"(" + amountExercises + ")"}
                        onClickEdit={() => {
                            setLastSourceSelected(undefined);
                            history.push("/create/summary");
                        }}
                    >
                        <></>
                    </AccordionTab>
                </StickyWrapper>
            );
        } else {
            return <div />;
        }
    };

    if (creatingMode === "RECURRING_TASK") {
        return <SelectBookRT />;
    } else {
        return (
            <Wrapper>
                <ExerciseSheetTitle />
                {renderAccordionTabVocabulary()}
                {renderAccordionTabExercises()}
                {!userInfo.isAnonymousUser && (
                    <CtasWrapper showScrollToTop>
                        <StyledButton
                            onClick={handleBackButton}
                            arrowDirection={"LEFT"}
                            buttonStyle={"BLANK"}
                        >
                            {t_back}
                        </StyledButton>
                    </CtasWrapper>
                )}
                <NoOwnContentModal
                    isOpen={isNoOwnContentModalOpen}
                    closeModalCallback={() => {
                        setIsNoOwnContentModalOpen(false);
                    }}
                    continueCallback={handleGoToWebAppClick}
                />
            </Wrapper>
        );
    }
};

export default SelectBook;
