import React, { useEffect } from "react";
import { useGeneralContext } from "../../context/AppGeneralContext";

import {
    AccordionTabWrapper,
    AccordionTabSummaryWrapper,
    AccordionTabSummaryContentWrapper,
    AccordionTabSummaryItem,
    AccordionTabSummary,
    AccordionTabText,
    StyledIcon,
    AccordionTabActionsWrapper,
    AccordionTabDetailsWrapper,
} from "./StyledComponents";
import { animate } from "framer-motion";

interface AccordionTabProps {
    title: string;
    mainIconName: string;
    counterValue?: string;
    isActive?: boolean;
    hideActions?: boolean;
    showAccordionTabDetails?: boolean;
    toggleAccordionTabDetails?: () => void;
    onClickEdit?: () => void;
    children?: JSX.Element | JSX.Element[];
    enableTextPulse?: boolean;
}

const AccordionTab: React.FC<AccordionTabProps> = (props) => {
    const { userInfo } = useGeneralContext();

    const onClickEdit = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        props.onClickEdit?.();
    };

    useEffect(() => {
        if (props.enableTextPulse) {
            const controls = animate(
                "#accordion-text-to-animate",
                { scale: [1, 1.5, 1] },
                { duration: 0.4, ease: "easeInOut" }
            );

            return () => {
                controls.stop();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.counterValue]);

    return (
        <AccordionTabWrapper>
            <AccordionTabSummaryWrapper
                onClick={() => props.toggleAccordionTabDetails?.()}
                clickable={!!props.toggleAccordionTabDetails}
                isAnonymous={userInfo.isAnonymousUser}
            >
                <AccordionTabSummaryContentWrapper borderBottom={props.showAccordionTabDetails}>
                    <AccordionTabSummary>
                        <AccordionTabSummaryItem>
                            <StyledIcon
                                name={props.mainIconName}
                                $primaryColor={props.isActive}
                            />
                        </AccordionTabSummaryItem>
                        <AccordionTabSummaryItem>
                            <AccordionTabText primaryColor={props.isActive}>{props.title}</AccordionTabText>
                        </AccordionTabSummaryItem>
                        {props.counterValue && (
                            <AccordionTabSummaryItem>
                                <AccordionTabText
                                    // only sets the id when enableTextPulse exists to prevent unintended interactions with other accordions
                                    id={`${props.enableTextPulse ? "accordion-text-to-animate" : ""}`}
                                    primaryColor={props.isActive}
                                >
                                    {props.counterValue}
                                </AccordionTabText>
                            </AccordionTabSummaryItem>
                        )}
                    </AccordionTabSummary>
                    {!props.hideActions && (
                        <AccordionTabActionsWrapper>
                            {props.onClickEdit && (
                                <AccordionTabSummaryItem>
                                    <StyledIcon
                                        name={"edit-avatar"}
                                        onClick={onClickEdit}
                                    />
                                </AccordionTabSummaryItem>
                            )}
                            {props.toggleAccordionTabDetails && (
                                <AccordionTabSummaryItem>
                                    <StyledIcon
                                        name={props.showAccordionTabDetails ? "chevron-up" : "chevron-down"}
                                        $mediumWeight
                                    />
                                </AccordionTabSummaryItem>
                            )}
                        </AccordionTabActionsWrapper>
                    )}
                </AccordionTabSummaryContentWrapper>
            </AccordionTabSummaryWrapper>
            {props.showAccordionTabDetails && <AccordionTabDetailsWrapper>{props.children}</AccordionTabDetailsWrapper>}
        </AccordionTabWrapper>
    );
};

export default AccordionTab;
