import styled from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../constants/DesignConstants";
import ArrowButton from "../../../basic/arrowButton/ArrowButton";

export const UnitWrapper = styled.div`
    background: white;
    padding: ${DesignConstants.SPACES.XS} 0;
    margin: ${DesignConstants.SPACES.XXS} 0;
    user-select: none;
    scroll-margin-top: 180px;
`;

export const UnitHeaderWrapper = styled.div<{ hideBottomLine?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) =>
        props.hideBottomLine
            ? `0 ${DesignConstants.SPACES.S} 0 0`
            : `0 ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.S} 0`};
    border-bottom: ${(props) =>
        props.hideBottomLine ? "" : `${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.LIGHT_GREY}`};
`;

export const SelectionWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: ${DesignConstants.SPACES.XS};
    width: 2rem;
    justify-content: space-around;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        align-items: flex-start;
        align-self: flex-start;
        padding-top: ${DesignConstants.SPACES.XXS};
    }
`;

export const CheckIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;

export const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        gap: 0.5rem;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: ${DesignConstants.SPACES.S};
`;

export const CardListingWrapper = styled.div`
    max-height: 20rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        max-height: unset;
    }
`;

export const UnitNameWrapper = styled.div`
    width: 100%;
    cursor: pointer;
`;

export const SelectedCardsCountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
`;

export const OpenClosedWrapper = styled.div`
    cursor: pointer;
`;

export const StyledArrowButton = styled(ArrowButton)`
    padding: 0 !important;
`;

export const UserWarningWrapper = styled.div`
    margin-top: ${DesignConstants.SPACES.S};
    padding: 0 ${DesignConstants.SPACES.S};
`;

export const UnregisteredUserWarning = styled.span`
    font-style: italic;
`;

export const Checkbox = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 0.2rem;
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: normal;
    text-transform: lowercase;

    &.checked {
        border-color: ${DesignConstants.COLORS.P6_ORANGE};
        background: ${DesignConstants.COLORS.P6_ORANGE};
        color: white;
    }

    &.partial {
        border-color: ${DesignConstants.COLORS.MID_GREY};
        background: ${DesignConstants.COLORS.MID_GREY};
        color: white;
    }
`;

export const CenterDash = styled.span`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    transform: translate(0px, -3px);
`;
