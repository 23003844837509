import * as React from "react";
import { ReactComponent as NextArrow } from "../../assets/icons/skip-card.svg";
import DesignConstants from "../../constants/DesignConstants";
import { LeftArrow, RightArrow, UpArrow, DownArrow, ArrowButtonWrapper } from "./StyledComponents";

export interface ArrowButtonProps {
    className?: string;
    onClick: () => any;
    disabled?: boolean;
    direction: "LEFT" | "RIGHT" | "UP" | "DOWN";
    arrowColor?: string;
}

const ArrowButton: React.FC<ArrowButtonProps> = (props) => {
    let component;

    switch (props.direction) {
        case "LEFT":
            component = (
                <LeftArrow
                    svgComponent={NextArrow}
                    color={
                        props.arrowColor
                            ? props.arrowColor
                            : !!props.disabled
                            ? DesignConstants.COLORS.TRANSPARENT_BLACK
                            : DesignConstants.COLORS.DARK_GREY
                    }
                />
            );
            break;
        case "RIGHT":
            component = (
                <RightArrow
                    svgComponent={NextArrow}
                    color={
                        props.arrowColor
                            ? props.arrowColor
                            : !!props.disabled
                            ? DesignConstants.COLORS.TRANSPARENT_BLACK
                            : "white"
                    }
                />
            );
            break;
        case "UP":
            component = (
                <UpArrow
                    svgComponent={NextArrow}
                    color={
                        props.arrowColor
                            ? props.arrowColor
                            : !!props.disabled
                            ? DesignConstants.COLORS.TRANSPARENT_BLACK
                            : DesignConstants.COLORS.DARK_GREY
                    }
                />
            );
            break;

        case "DOWN":
            component = (
                <DownArrow
                    svgComponent={NextArrow}
                    color={
                        props.arrowColor
                            ? props.arrowColor
                            : !!props.disabled
                            ? DesignConstants.COLORS.TRANSPARENT_BLACK
                            : DesignConstants.COLORS.DARK_GREY
                    }
                />
            );
            break;
    }

    return (
        <ArrowButtonWrapper
            onClick={props.disabled ? () => {} : props.onClick}
            className={`${props.className || ""} ${props.disabled ? "disabled" : ""}`}
        >
            {component}
        </ArrowButtonWrapper>
    );
};

export default ArrowButton;
