// LIBRARIES
import React, { useEffect, useState } from "react";
import { useT } from "@transifex/react";
import {
    AeaExerciseModel,
    AvailableBandsInfo,
    AvailableUserOwnSubjectsInfo,
    DeleteExercisesOrGroupProperties,
} from "p6m-p6u";
import { CoverImage } from "p6-react-shared";
import { formatUTCTime } from "../../../helpers/DateTime";
import { logEvent, logEventWithProps } from "../../../logging/Logger";
import UserContentCover from "../../userContentCover/UserContentCover";

import {
    Wrapper,
    BookCoverAndCTAWrapper,
    BookCoverAndName,
    BookName,
    BookItemCTAs,
    ItemCTAs,
    DeleteIconWrapper,
    ExerciseItemContent,
    StretchExerciseListingSideIcon,
    ExercisesListWrapper,
    ExerciseItemWrapper,
    ExerciseInfoWrapper,
    ExerciseCardsCountWrapper,
    ExerciseDateWrapper,
    ExerciseFromText,
    SmallerNormalButton,
    SmallerButtonWithIcon,
    MediumIcon,
    ShowHideAllExercisesWrapper,
    StyledArrowButton,
} from "./StyledComponents";

export interface ExerciseAndBookInfoProps {
    bookExercises: Array<AeaExerciseModel>;
    bookInfoData: AvailableBandsInfo;
    subjectInfoData: AvailableUserOwnSubjectsInfo;
    bookId: string;
    addVocabularyToExercisesFn: () => any;
    openDeleteModalFn: (properties: DeleteExercisesOrGroupProperties) => any;
    toggleCopyModalFn: (val: boolean) => any;
    groupId: string;
    canLogEvent: () => any;
}

const ExerciseAndBookInfo: React.FC<ExerciseAndBookInfoProps> = (props) => {
    const {
        bookExercises,
        bookInfoData,
        bookId,
        addVocabularyToExercisesFn,
        openDeleteModalFn,
        groupId,
        toggleCopyModalFn,
        canLogEvent,
        subjectInfoData,
    } = props;

    const t = useT();
    const t_addVocabulary = t("Add new Vocabulary", { _tags: "CreateTest,ExerciseAndBookInfo" });
    const t_lastExercise = t("Last Exercise:", { _tags: "CreateTest,ExerciseAndBookInfo" });
    const t_exerciseFrom = t("Exercise from:", { _tags: "CreateTest,ExerciseAndBookInfo" });
    const t_copylink = t("Copy Link", { _tags: "CreateTest,ExerciseAndBookInfo" });

    const [showAllTheExercises, setShowAllTheExercises] = useState(false);

    function copyShareLink(link: string, exerciseId: string, groupId: string) {
        navigator.clipboard.writeText(link);
        toggleCopyModalFn(true);

        if (canLogEvent()) {
            logEventWithProps("Copied Exercise Link", {
                exerciseId,
                groupId,
            });
        }
    }

    function sendClickCardsCountEvent() {
        if (canLogEvent()) {
            logEvent("Click on Vokabelvorschau");
        }
    }

    useEffect(() => {
        const hasLasCreatedExercise = bookExercises.find((e) => e.wasCreatedLast);
        if (hasLasCreatedExercise) {
            setShowAllTheExercises(true);
        }
    }, [bookExercises]);

    const renderBookCoverAndName = () => {
        return (
            <BookCoverAndName>
                {bookExercises[0].isOwnContentTask ? (
                    <>
                        {subjectInfoData[bookId]?.primaryLang && (
                            <UserContentCover
                                primaryLanguage={subjectInfoData[bookId].primaryLang!}
                                secondaryLanguage={subjectInfoData[bookId]?.secondaryLang}
                                small
                            />
                        )}
                    </>
                ) : (
                    <>
                        {bookInfoData[bookId]?.band?.Image && (
                            <CoverImage
                                imageId={"https://www.phase-6.de" + bookInfoData[bookId].band.Image}
                                size={"small"}
                            />
                        )}
                    </>
                )}
                {bookExercises[0].isOwnContentTask ? (
                    <BookName>{subjectInfoData[bookId]?.name}</BookName>
                ) : (
                    <BookName>{bookInfoData[bookId]?.band?.Name}</BookName>
                )}
            </BookCoverAndName>
        );
    };

    const renderBookItemCTAs = () => {
        return (
            <BookItemCTAs>
                <SmallerButtonWithIcon
                    iconPosition={"LEFT"}
                    icon={"add"}
                    buttonStyle={"BLANK"}
                    onClick={addVocabularyToExercisesFn}
                >
                    {t_addVocabulary}
                </SmallerButtonWithIcon>
                <DeleteIconWrapper
                    onClick={() => {
                        openDeleteModalFn({ itemType: "BOOK", bookId, groupId });
                    }}
                >
                    <MediumIcon name={"trash"} />
                </DeleteIconWrapper>
            </BookItemCTAs>
        );
    };

    const renderExercisesList = () => {
        return (showAllTheExercises && bookExercises.length > 1 ? bookExercises : bookExercises.slice(0, 1)).map(
            (c, index) => {
                if (c.id) {
                    return (
                        <ExerciseItemWrapper
                            key={c.id}
                            className={c.wasCreatedLast ? "highlighted" : ""}
                        >
                            <StretchExerciseListingSideIcon
                                isLast={index === bookExercises.length - 1}
                                isListClosed={!showAllTheExercises}
                                hasMoreThanOneItem={bookExercises.length > 1}
                            />
                            <ExerciseItemContent>
                                <ExerciseInfoWrapper>
                                    <ExerciseFromText>{index === 0 ? t_lastExercise : t_exerciseFrom}</ExerciseFromText>
                                    <ExerciseDateWrapper>
                                        <MediumIcon name={"calendar-check"} />{" "}
                                        {c.createdDate ? formatUTCTime(c.createdDate) : ""}
                                    </ExerciseDateWrapper>
                                    <ExerciseCardsCountWrapper onClick={sendClickCardsCountEvent}>
                                        <MediumIcon name={"documents"} /> {c.cardIds?.length}
                                    </ExerciseCardsCountWrapper>
                                </ExerciseInfoWrapper>
                                <ItemCTAs>
                                    {c.shareLink && (
                                        <SmallerNormalButton
                                            buttonStyle={"BLANK"}
                                            onClick={() => {
                                                copyShareLink(c.shareLink!, c.id!, groupId);
                                            }}
                                        >
                                            {t_copylink}
                                        </SmallerNormalButton>
                                    )}
                                    <DeleteIconWrapper
                                        onClick={() => {
                                            openDeleteModalFn({ itemType: "EXERCISE", exerciseId: c.id! });
                                        }}
                                    >
                                        <MediumIcon name={"trash"} />
                                    </DeleteIconWrapper>
                                </ItemCTAs>
                            </ExerciseItemContent>
                        </ExerciseItemWrapper>
                    );
                } else {
                    return null;
                }
            }
        );
    };

    const rendeShowHideExercises = () => {
        return (
            bookExercises.length > 1 && (
                <ShowHideAllExercisesWrapper
                    onClick={() => {
                        setShowAllTheExercises(!showAllTheExercises);
                    }}
                >
                    <StyledArrowButton
                        onClick={() => {}}
                        direction={showAllTheExercises ? "UP" : "DOWN"}
                    />
                </ShowHideAllExercisesWrapper>
            )
        );
    };

    return (
        <Wrapper>
            <BookCoverAndCTAWrapper>
                {renderBookCoverAndName()}
                {renderBookItemCTAs()}
            </BookCoverAndCTAWrapper>
            <ExercisesListWrapper>
                {renderExercisesList()}
                {rendeShowHideExercises()}
            </ExercisesListWrapper>
        </Wrapper>
    );
};

export default ExerciseAndBookInfo;
