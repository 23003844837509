import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import styled, { keyframes } from "styled-components";
import ArrowButton from "../../../basic/arrowButton/ArrowButton";
import { DateTimePicker } from "@mui/x-date-pickers";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import StyledButton from "../../../basic/styledButton/StyledButton";
import DesignConstants from "../../../constants/DesignConstants";

export const TestListElementOuterWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: ${DesignConstants.SPACES.S};
    user-select: none;
`;

export const TestListElementInnerWrapper = styled.div`
    width: 95%;
    background: white;
    padding: ${DesignConstants.SPACES.S};
    border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER};

    @media (max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px) {
        width: 100%;
        padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.XS};
    }
`;

export const TestListElementHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        gap: 0;
    }
`;

export const OwnSubjectNotAvailableWarningWrapper = styled.div`
    text-align: center;
`;

export const TestListElementDetails = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${DesignConstants.SPACES.XS};
`;

export const TestNameAndDateWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
    }
`;

export const TestListElementOpenActions = styled.div``;

export const StyledArrowButton = styled(ArrowButton)`
    padding: 0 !important;
`;

export const TestName = styled.span`
    font-weight: ${DesignConstants.FONT_WEIGHTS.BOLD};
    display: block;
    flex: 1;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin-left: ${DesignConstants.SPACES.XXS};
    }
`;
export const TestDateAndShareWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
`;

export const TestDate = styled.span`
    font-size: ${DesignConstants.FONT_SIZES.M};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        text-align: center;
    }
`;

export const DateAndShareStatusWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${DesignConstants.SPACES.L};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        justify-content: center;
        gap: ${DesignConstants.SPACES.S};
    }
`;

export const TestActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${DesignConstants.SPACES.S} 0 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap-reverse;
    }
`;

export const TabsWrapper = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    width: 80%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

export const TestActionsButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
    gap: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        justify-content: space-evenly;
        width: 100%;
        margin: ${DesignConstants.SPACES.S} 0;
    }

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        user-select: none;
        cursor: not-allowed;
        pointer-events: none;
    }
`;

export const TabWrapper = styled.div`
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.L};
    cursor: pointer;
    border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER};
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    margin-right: ${DesignConstants.SPACES.S};

    &.selected {
        border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER}
            ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER} 0 0;
        padding-bottom: 1.2rem;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        text-align: center;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const TabContentWrapper = styled.div`
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    border-radius: 0 ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER} ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER}
        ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER};
    padding: 1rem;

    a:link {
        text-decoration: none;
    }
`;

export const ActionIconWrapper = styled.div`
    &.disabled {
        pointer-events: none;
        opacity: ${DesignConstants.OPACITY.HALF};
    }
`;

export const PDFCtasWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: ${DesignConstants.SPACES.S} 0 0;
    gap: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
    }
`;

export const OnlineTestInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
    }
`;

export const GradeTestWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    align-self: stretch;
    padding: ${DesignConstants.SPACES.XS} 0 ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.XS};
`;

export const TestShareWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 75%;
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.XS} 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        width: 100%;
    }

    &.disabled {
        pointer-events: none;
        opacity: ${DesignConstants.OPACITY.HALF};
    }
`;

export const Separator = styled.div`
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};
    margin: 0 ${DesignConstants.SPACES.XS};
    align-self: stretch;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        margin: ${DesignConstants.SPACES.XS} 0;
    }
`;
export const CurrentAnswersCount = styled.h1``;

export const TeacherCommentWrapper = styled.div`
    flex: 3;
    padding: 0 ${DesignConstants.SPACES.XS};
    display: flex;
    flex-direction: column;
    align-self: stretch;
`;

export const TestConfigWrapper = styled.div`
    flex: 2;
    padding: 0 ${DesignConstants.SPACES.XS};
`;

export const TestInfoText = styled.p`
    margin-bottom: ${DesignConstants.SPACES.XS};
`;

export const CommentTextArea = styled.textarea`
    height: 100%;
    border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER};
    margin-bottom: ${DesignConstants.SPACES.S};
    padding: ${DesignConstants.SPACES.XS};
    border: solid 1px ${DesignConstants.COLORS.LIGHT_GREY};
    font-size: ${DesignConstants.FONT_SIZES.S};
    font-family: inherit;
    min-height: 150px;

    &[readonly] {
        background: ${DesignConstants.COLORS.LIGHT_GREY};
        cursor: not-allowed;
    }

    &[disabled] {
        cursor: not-allowed;
        &:hover {
            border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};
        }
    }

    &.invalid {
        border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.RED};
        margin-bottom: 5px;
    }

    &:hover {
        border: ${DesignConstants.BORDERS.THIN} black;
    }
`;

export const StyledKeyboardDateTimePicker = styled(DateTimePicker)`
    width: 100%;

    > div {
        border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER} !important;
    }

    input {
        padding: ${DesignConstants.SPACES.S} 0 ${DesignConstants.SPACES.S} 0.8rem;
    }

    &.invalid .MuiOutlinedInput-notchedOutline {
        border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.RED};
    }
`;

export const DurationWrapper = styled.div`
    margin-bottom: ${DesignConstants.SPACES.S};
`;
export const DeadlineWrapper = styled.div`
    margin-bottom: ${DesignConstants.SPACES.S};
`;

export const TestConfigCTAWrapper = styled.div``;

export const ShareCTA = styled(StyledButtonWithIcon)`
    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

export const ShareInfo = styled.div`
    font-size: ${DesignConstants.FONT_SIZES.M};
    display: inline;
    white-space: nowrap;
    max-width: 10px; // needed to ensure the text inside will never affect the width of the parent div
`;

export const ShareLink = styled.span`
    color: ${DesignConstants.COLORS.P6_ORANGE};
    user-select: text;
`;

export const SaveChangesCTA = styled(StyledButton)`
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin-bottom: ${DesignConstants.SPACES.S};
    }
`;

export const PreviewWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-start;
`;

export const BookCoverWrapper = styled.div`
    /* This height is set by the book cover image shared component */
    height: 100px;
`;

export const PreviewCTA = styled(StyledButtonWithIcon)`
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        margin-bottom: ${DesignConstants.SPACES.S};
    }
`;

export const NormalSizeIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.M};
`;

const spinAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
`;

export const AnimatedNormalSizeIcon = styled(PhaseSixIcon)`
    animation: ${spinAnimation} 1.5s infinite;
`;

export const BiggerSizeIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};

    &.disabled {
        cursor: not-allowed;
    }
`;

export const CTASpan = styled.span`
    margin-right: 0.8rem;
`;

export const DuplicateInputWrapper = styled.div`
    border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER};
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    width: 100%;
    padding: 0.7rem ${DesignConstants.SPACES.S};
`;

export const TaskName = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.L};
`;
export const DuplicateConfirmationWrapper = styled.div`
    width: 100%;
    margin-bottom: 0;
`;
export const DuplicateConfirmation = styled.p``;

export const DeleteConfirmationWrapper = styled.div`
    width: 100%;
    margin-bottom: 0;
    text-align: center;
`;
export const DeleteConfirmation = styled.p``;
export const DeleteConfirmationInvalidLink = styled.p``;

export const ShareConfirmationWrapper = styled.div`
    width: 70%;
    margin-bottom: 0;
`;
export const ShareConfirmation = styled.p`
    text-align: center;
    line-height: 1.5rem;
`;
export const ShareConfirmationDataNotice = styled.p`
    padding-top: 20px;
`;

export const InvalidTeacherComment = styled.p`
    color: ${DesignConstants.COLORS.RED};
    margin-bottom: ${DesignConstants.SPACES.S};
`;
