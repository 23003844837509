import React, { useState } from "react";
import { useT } from "@transifex/react";
import { useHistory } from "react-router-dom";
import { useTestCreationContext } from "../../../context/TestCreationContext";

import { CoverImage } from "p6-react-shared";
import AccordionTab from "../../../basic/accordionTab/AccordionTab";

import {
    VocabularyInformationWrapper,
    BookImageWrapper,
    BookName,
    VocabularyInformationText,
    AmountText,
    VocabularyAmountWrapper,
    VocabularyDetailsWrapper,
} from "./StyledComponents";

interface SummaryVocabularyAccordionProps {}

const SummaryVocabularyAccordion: React.FC<SummaryVocabularyAccordionProps> = (props) => {
    const { vocabularyDrawerContent, selectedBookData } = useTestCreationContext();

    const history = useHistory();

    const t = useT();

    const t_wordsSelected = t("Words selected:", { _tags: "CreateTest,Summary" });
    const t_wordsUsed = t("Words used:", { _tags: "CreateTest,Summary" });
    const t_OverallVocabulary = t("Overall Vocabulary", { _tags: "CreateTest,Tab" });

    const [showAccordionTabVocabularyDetails, setShowAccordionTabVocabularyDetails] = useState<boolean>(false);

    const usedVocabulary =
        Object.values(vocabularyDrawerContent).filter((word) => word.exerciseIds && word.exerciseIds.length > 0)
            .length ?? 0;
    const availableVocabulary = Object.values(vocabularyDrawerContent).length || 0;

    return (
        <AccordionTab
            mainIconName={"vocabulary-list-unset"}
            title={t_OverallVocabulary}
            counterValue={`(${availableVocabulary})`}
            showAccordionTabDetails={showAccordionTabVocabularyDetails}
            toggleAccordionTabDetails={() => setShowAccordionTabVocabularyDetails(!showAccordionTabVocabularyDetails)}
            onClickEdit={() => {
                history.push("/create/select-vocabulary");
            }}
        >
            <VocabularyInformationWrapper>
                <BookImageWrapper>
                    {selectedBookData.band?.Image && (
                        <CoverImage
                            imageId={"https://www.phase-6.de" + selectedBookData.band?.Image}
                            size={"small"}
                        />
                    )}
                </BookImageWrapper>
                <VocabularyInformationText>
                    <BookName>
                        {selectedBookData.publisherBook?.BookName} ({selectedBookData.publisherBook?.PublisherName}) (
                        {selectedBookData.band?.ShortName})
                    </BookName>
                    <VocabularyDetailsWrapper>
                        <VocabularyAmountWrapper>
                            <AmountText>{t_wordsUsed}</AmountText>
                            <AmountText>{usedVocabulary}</AmountText>
                        </VocabularyAmountWrapper>
                        <VocabularyAmountWrapper>
                            <AmountText>{t_wordsSelected}</AmountText>
                            <AmountText>{availableVocabulary}</AmountText>
                        </VocabularyAmountWrapper>
                    </VocabularyDetailsWrapper>
                </VocabularyInformationText>
            </VocabularyInformationWrapper>
        </AccordionTab>
    );
};

export default SummaryVocabularyAccordion;
