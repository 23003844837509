import styled from "styled-components";
import PhaseSixIcon from "../../../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../../../constants/DesignConstants";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
`;

export const LabelWrapper = styled.div``;

export const Label = styled.p`
    border: ${DesignConstants.BORDERS.MEDIUM};
    border-color: transparent;
    pointer-events: none;
    user-select: none;
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const TaskAndActionsWrapper = styled.div`
    flex: 1;
    display: flex;
    gap: ${DesignConstants.SPACES.S};
`;

export const TaskWrapper = styled.div<{ isFocused?: boolean; highlighted?: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    border: ${DesignConstants.BORDERS.MEDIUM};
    border-color: ${({ isFocused, highlighted }) => {
        if (highlighted) return DesignConstants.COLORS.P6_ORANGE;
        if (isFocused) return DesignConstants.COLORS.MID_GREY;
        return "transparent";
    }};
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    padding: 0 ${DesignConstants.SPACES.S};
`;

export const Input = styled.input`
    flex: 1;
    display: flex;
    padding: ${DesignConstants.SPACES.XXS} 0;
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-family: inherit;
    color: inherit;
    outline: none;
    border: none;
`;

export const Text = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-family: inherit;
    padding: ${DesignConstants.SPACES.XXS} 0;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    gap: ${DesignConstants.SPACES.XS};
`;

export const IconWrapper = styled.div`
    width: ${DesignConstants.SPACES.L};
    cursor: pointer;

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        cursor: not-allowed;
    }
`;

export const ActionIcon = styled(PhaseSixIcon)`
    width: ${DesignConstants.SPACES.L};
    font-size: ${DesignConstants.FONT_SIZES.XXL};
`;

export const AlertIcon = styled(PhaseSixIcon)`
    width: ${DesignConstants.SPACES.L};
    font-size: ${DesignConstants.FONT_SIZES.XL};
    color: ${DesignConstants.COLORS.P6_ORANGE};
`;
