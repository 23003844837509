import React, { useState } from "react";
import { useT } from "@transifex/react";
import { ExerciseTypes, Exercises } from "../../../../../enums/exercises";
import { useTestCreationContext } from "../../../../../context/TestCreationContext";
import InputWithActions from "../../../../../basic/inputWithActions/InputWithActions";

import { Wrapper, LabelWrapper, Label } from "./StyledComponents";

interface ExerciseTaskProps {}

const ExerciseTask: React.FC<ExerciseTaskProps> = () => {
    const { currentExerciseModel, updateCurrentExerciseProperties, setIsTestSynced } = useTestCreationContext();

    const t = useT();
    const t_task = t("Exercise Task", { _tags: "CreateTest,CreateOrEditExercise" });
    const t_freeTextPlaceholder = t("Click here to add an Instruction.", { _tags: "CreateTest,CreateOrEditExercise" });

    const isFreeTextExercise = currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FREE_TEXT);

    const [isEditing, setIsEditing] = useState(isFreeTextExercise && !currentExerciseModel.extraComments);
    const [currentText, setCurrentText] = useState(currentExerciseModel.extraComments ?? "");

    function saveChanges() {
        if ((currentText !== "" || isFreeTextExercise) && currentText !== currentExerciseModel.extraComments) {
            updateCurrentExerciseProperties({ extraComments: currentText });
            setIsTestSynced(false);
        } else {
            setCurrentText(currentExerciseModel.extraComments ?? "");
        }

        setIsEditing(false);
    };

    function discardChanges() {
        setCurrentText(currentExerciseModel.extraComments ?? "");
        setIsEditing(false);
    };

    return (
        <Wrapper>
            <LabelWrapper>
                <Label>{t_task}</Label>
            </LabelWrapper>
            <InputWithActions
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                currentText={currentText}
                onChange={(e) => setCurrentText(e.target.value)}
                onSave={saveChanges}
                onDiscard={discardChanges}
                placeholder={isFreeTextExercise && !isEditing ? t_freeTextPlaceholder : ""}
                showWarning={isFreeTextExercise && !currentText}
                isSaveButtonDisabled={currentText === ""}
            />
        </Wrapper>
    );
};

export default ExerciseTask;
