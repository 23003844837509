import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";
import PhaseSixIcon from "../../../../../../basic/phaseSixIcon/PhaseSixIcon";

export const ExerciseNumber = styled.div`
    height: ${DesignConstants.SPACES.L};
    width: ${DesignConstants.SPACES.L};
    border-radius: 10px;
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: ${DesignConstants.FONT_SIZES.S};
`;

export const SelectExerciseVocabularyRowWrapper = styled.div<{ isRowAnimating: boolean }>`
    display: flex;
    align-items: center;
    border-bottom: ${DesignConstants.BORDERS.MEDIUM};
    :last-child {
        border-bottom: none;
    }
    border-color: ${DesignConstants.COLORS.LIGHT_GREY};
    padding: ${DesignConstants.SPACES.XXS};
    background: ${({ isRowAnimating }) => (isRowAnimating ? DesignConstants.COLORS.BACKGROUND_LIGHT : "white")};

    ${ExerciseNumber} {
        background: ${({ isRowAnimating }) => isRowAnimating && DesignConstants.COLORS.LIGHT_GREY};
    }

    &:hover {
        cursor: ${({ isRowAnimating }) => (isRowAnimating ? "default" : "pointer")};
        background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};

        ${ExerciseNumber} {
            background: ${DesignConstants.COLORS.LIGHT_GREY};
        }
    }

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        cursor: ${({ isRowAnimating }) => (isRowAnimating ? "default" : "not-allowed")};
    }
`;

export const SelectExerciseVocabularyRowWordContainer = styled.div`
    flex: 1;
`;

export const SelectExerciseVocabularyRowWord = styled.span`
    display: block;
    font-size: ${DesignConstants.FONT_SIZES.M};
    padding-right: ${DesignConstants.SPACES.S_OLD};
`;

export const WordContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
`;

export const AddIconWrapper = styled.div`
    padding: ${DesignConstants.SPACES.XS};
    display: flex;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
    }
`;

export const StyledAddIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    color: ${DesignConstants.COLORS.MID_GREY};
`;

export const ExercisesNumbersWrapper = styled.div`
    display: flex;
    gap: ${DesignConstants.SPACES.XS};

    &:hover {
        cursor: default;
    }

    &.disabled {
        cursor: not-allowed;
    }
`;

export const WordContainer = styled.span`
    display: inline-block;
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.S};
    margin: 0 ${DesignConstants.SPACES.XS};
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.LIGHT_GREY};
`;

export const Word = styled.span`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const ToolTipWrapper = styled.div`
    display: flex;
    height: ${DesignConstants.SPACES.L};
    align-items: center;
`;

export const ToolTipText = styled.span`
    display: block;
    font-size: ${DesignConstants.FONT_SIZES.M};
    color: ${DesignConstants.COLORS.DARK_GREY};
`;
