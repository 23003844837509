import React, { ReactElement } from "react";
import { Exercises } from "../../../enums/exercises";
import { UT } from "@transifex/react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import DesignConstants from "../../../constants/DesignConstants";
import standardExercise from "../../../assets/img/exerciseTypes/Standard.png";
import connectWordsExercise from "../../../assets/img/exerciseTypes/ConnectWords.png";
import gapSentenceExercise from "../../../assets/img/exerciseTypes/GapSentence.png";
import createSentenceExercise from "../../../assets/img/exerciseTypes/CreateSentence.png";
import createTextExercise from "../../../assets/img/exerciseTypes/CreateText.png";
import freeTextExercise from "../../../assets/img/exerciseTypes/FreeText.png";

import {
    ExerciseWrapper,
    ExerciseHeaderWrapper,
    ExerciseNameWrapper,
    ExerciseName,
    ExerciseInfo,
    ExerciseQuestionMarkWrapper,
    StyledQuestionMarkIcon,
    ExerciseDescriptionWrapper,
    ExerciseDescriptionTextWrapper,
    ExerciseDescriptionImageWrapper,
    ExerciseDescriptionImage,
} from "./StyledComponents";

const getImageSource = (exerciseType: Exercises) => {
    switch (exerciseType) {
        case Exercises.STANDARD:
            return standardExercise;
        case Exercises.CONNECT_WORDS:
            return connectWordsExercise;
        case Exercises.FILL_GAP:
            return gapSentenceExercise;
        case Exercises.WRITE_SENTENCE:
            return createSentenceExercise;
        case Exercises.CREATE_TEXT:
            return createTextExercise;
        case Exercises.FREE_TEXT:
            return freeTextExercise;
        default:
            return "";
    }
};

const getDescription = (exerciseType: Exercises): ReactElement | string => {
    switch (exerciseType) {
        case Exercises.STANDARD:
            return (
                <UT
                    _str="In the <b>standard</b> exercise, the words and their sole meaning are going to be asked. The students will fill in the gap with the translation, either on the target language or base language or randomly. "
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        case Exercises.CONNECT_WORDS:
            return (
                <UT
                    _str="In this exercise the students have to <b>connect words</b> that are in two columns, each of five words. Like the Standard exercise, this exercise allows the practice of the definition of the words."
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        case Exercises.FILL_GAP:
            return (
                <UT
                    _str="In this exercise, the example phrases from the book will be shown as <b>gap sentences</b>. The words that belong in the gap, are located in a box on top of the sentences. The students need to translate the words and find the right context. <b>Info:</b> It is possible that in your selected Book, there are not or only a few gap sentences available."
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        case Exercises.WRITE_SENTENCE:
            return (
                <UT
                    _str="In this exercise, the students need to translate the word and <b>create one or more sentences,</b> where the word will be used. This exercise type demands writing and creativity. For you as a teacher, there's a lot of possibilities for different types of types of tasks, for example with different tenses."
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        case Exercises.CREATE_TEXT:
            return (
                <UT
                    _str="In this exercise, you choose the words and the students will have to translate the words and then create a coherent text from them, you can specify the length. By making a contextual connection between the words, students must also reflect and apply what they have learned in previous lessons. In addition to the text length, you can also add further instructions on, for example, text form or tense."
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        case Exercises.FREE_TEXT:
            return (
                <UT
                    _str="With this optional <b>Free text</b> you can add directions, explanations or have place for extra tasks or notes. Additionally this free text is independent from the content of the schoolbook, so you can use it for individual grammar exercises or a free writing exercise."
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        default:
            return "";
    }
};

export interface SelectExerciseTypeItemProps {
    exerciseType: Exercises;
    description?: string;
    infoText?: string;
    isDisabled?: boolean;
    isInfoHidden?: boolean;
    isInfoOpen: boolean;
    onToggleInfo: (event: React.MouseEvent<HTMLDivElement>) => void;
    onSelect: () => void;
}

const SelectExerciseTypeItem: React.FC<SelectExerciseTypeItemProps> = ({
    exerciseType,
    description,
    infoText,
    isDisabled = false,
    isInfoHidden = false,
    isInfoOpen,
    onToggleInfo,
    onSelect,
}) => {
    const { ExerciseTypesExerciseName } = useTestCreationContext();

    return (
        <ExerciseWrapper>
            <ExerciseHeaderWrapper
                className={isInfoOpen ? "infoOpen" : ""}
                onClick={onSelect}
                disabled={isDisabled}
            >
                <ExerciseNameWrapper>
                    <ExerciseName>{ExerciseTypesExerciseName.get(exerciseType)}</ExerciseName>
                    {infoText && <ExerciseInfo>{infoText}</ExerciseInfo>}
                </ExerciseNameWrapper>
                <ExerciseQuestionMarkWrapper
                    onClick={(event) => onToggleInfo(event)}
                    hidden={isInfoHidden}
                >
                    <StyledQuestionMarkIcon color={isInfoOpen ? DesignConstants.COLORS.P6_ORANGE : undefined}>
                        {"?"}
                    </StyledQuestionMarkIcon>
                </ExerciseQuestionMarkWrapper>
            </ExerciseHeaderWrapper>
            {isInfoOpen && (
                <ExerciseDescriptionWrapper>
                    <ExerciseDescriptionTextWrapper>
                        {description || getDescription(exerciseType)}
                    </ExerciseDescriptionTextWrapper>
                    <ExerciseDescriptionImageWrapper>
                        <ExerciseDescriptionImage src={getImageSource(exerciseType)} />
                    </ExerciseDescriptionImageWrapper>
                </ExerciseDescriptionWrapper>
            )}
        </ExerciseWrapper>
    );
};

export default SelectExerciseTypeItem;
