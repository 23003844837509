import styled from "styled-components";
import DesignConstants from "../../constants/DesignConstants";

export const BulletPointWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 ${DesignConstants.SPACES.S};
    gap: ${DesignConstants.SPACES.XS};
`;

export const BoldBulletPointText = styled.span`
    font-weight: ${DesignConstants.FONT_WEIGHTS.BOLD};
    font-size: ${DesignConstants.FONT_SIZES.L};
`;

export const BoldBulletPointTextWrapper = styled.div`
    gap: ${DesignConstants.SPACES.XXS};
`;

export const AnonymousModalContent = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 90%;
    gap: ${DesignConstants.SPACES.XS};
`;

export const HowToContinueText = styled.span`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    color: ${DesignConstants.COLORS.DARK_GREY};
    margin-bottom: ${DesignConstants.SPACES.XS};
`;

export const OptionTitleText = styled.span`
    display: flex;
    align-items: center;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    height: ${DesignConstants.FONT_SIZES.XL};
    color: ${DesignConstants.COLORS.DARK_GREY};
    text-align: center;
`;

export const WithoutAccountWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: ${DesignConstants.SPACES.S};
    background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    border: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.LIGHT_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    padding: ${DesignConstants.SPACES.S};
`;

export const WithAccountWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: ${DesignConstants.SPACES.S};
    background-color: ${DesignConstants.COLORS.P6_ORANGE_LIGHT_PASTEL};
    border: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.P6_ORANGE_MID_PASTEL};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    padding: ${DesignConstants.SPACES.S};
`;
