import styled from "styled-components";
import PhaseSixIcon from "../../../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../../../constants/DesignConstants";

export const Label = styled.label`
    pointer-events: none;
    user-select: none;
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const InfoIcon = styled(PhaseSixIcon)`
    width: ${DesignConstants.SPACES.M};
    font-size: ${DesignConstants.FONT_SIZES.XL};

    &.clickable {
        cursor: pointer;
    }
`;

export const EyeInfoWrapper = styled.div`
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER};
    display: flex;
    align-items: center;
    padding: 0 ${DesignConstants.SPACES.XS};
    height: ${DesignConstants.SIZES.ICON_XS};
    gap: ${DesignConstants.SPACES.XS};
    cursor: pointer;
`;

export const IconAndTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
`;

export const EyeExplanationWrapper = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: ${DesignConstants.SPACES.M};
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S};
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER};

    margin: ${DesignConstants.SPACES.XS} 0;
`;

export const ExplanationText = styled.div``;
