import styled from "styled-components";
import ArrowIcon from "../../../basic/arrowIcon/ArrowIcon";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import StyledButton from "../../../basic/styledButton/StyledButton";
import DesignConstants from "../../../constants/DesignConstants";

export const Wrapper = styled.div`
    margin-bottom: ${DesignConstants.SPACES.XXL};
`;

export const SourceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.S};
    margin-top: ${DesignConstants.SPACES.S};
`;

export const SourceOptionItem = styled.div<{ noBorder?: boolean }>`
    width: 100%;
    padding: ${DesignConstants.SPACES.S};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    cursor: pointer;
    align-self: stretch;
    user-select: none;
    background: white;
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.S};
    border: ${(props) => (props.noBorder ? "" : `2px solid ${DesignConstants.COLORS.LIGHT_GREY}`)};

    &.LAST {
        order: 999;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        padding: ${DesignConstants.SPACES.S} 0 ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.XS};
        gap: ${DesignConstants.SPACES.XS};
    }

    &.orange {
        color: ${DesignConstants.COLORS.P6_ORANGE};
        padding-bottom: ${DesignConstants.SPACES.XS};
        svg,
        .cls-1,
        .skipCardColor {
            fill: ${DesignConstants.COLORS.P6_ORANGE} !important;
        }
    }

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        pointer-events: none;
    }
`;

export const SourceOptionIconWrapper = styled.div`
    min-width: ${DesignConstants.SPACES.XXL};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SourceOptionArrowWrapper = styled.div`
    width: ${DesignConstants.SPACES.XL};
`;

export const SourceOptionItemTitleWrapper = styled.div`
    flex: 1;
`;

export const SourceOptionItemTitle = styled.p`
    font-weight: ${DesignConstants.FONT_WEIGHTS.BOLD};
    font-size: ${DesignConstants.SPACES.S};
`;

export const SourceOptionItemDesc = styled.p`
    font-size: ${DesignConstants.SPACES.S};
    margin-top: ${DesignConstants.SPACES.XS};
`;

export const RecentlyUsedBooksWrapper = styled.div<{ amountRecent: number }>`
    margin: 0 ${DesignConstants.SPACES.M} ${DesignConstants.SPACES.M};
    border-top: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};
    padding-top: ${DesignConstants.SPACES.S};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: ${(props) => props.amountRecent * 250 + "px"}) {
        flex-direction: column;
        gap: ${DesignConstants.SPACES.XS};
    }
`;

export const RecentlyUsedBook = styled.div<{ amountRecent: number }>`
    padding: ${DesignConstants.SPACES.XS};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 1px;
    width: 250px;
    flex: 1;

    @media (max-width: ${(props) => props.amountRecent * 250 + "px"}) {
        width: 100%;
        max-width: 100%;
        align-items: center;
        justify-content: flex-start;
    }
`;

export const RecentlyUsedBookCoverWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
    }
`;

export const RecentlyUsedBookTitle = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.M};
    font-weight: ${DesignConstants.FONT_WEIGHTS.BOLD};
`;

export const RecentlyUsedBookSubtitle = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.S};
`;

export const RecentlyUsedBookDate = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.S};
`;

export const RecentlyUsedBookInfo = styled.div`
    align-self: stretch;
    justify-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
`;

export const SourceChangeWarningWrapper = styled.div`
    width: 100%;
`;

export const SourceChangeWarning = styled.p``;

export const StyledArrowIcon = styled(ArrowIcon)`
    > i {
        font-size: ${DesignConstants.FONT_SIZES.XXL};
    }
`;

export const RecentBooksMainWrapper = styled.div`
    background: white;
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    border: 2px solid ${DesignConstants.COLORS.LIGHT_GREY};

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        pointer-events: none;
    }
`;

export const ChooseNewContentTitleWrapper = styled.div`
    order: 3;
    margin-top: ${DesignConstants.SPACES.L};
`;

export const ChooseNewContentTitle = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.M};
    font-weight: ${DesignConstants.FONT_WEIGHTS.BOLD};
`;

//including special icon size
export const StyledP6Icon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.SIZES.ICON_L};
`;

//including special icon size
export const BackIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.SIZES.ICON_M};
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CenteredStyledButton = styled(StyledButton)`
    &:only-child {
        margin-left: auto;
    }
`;

export const DisabledWhenEditingSpan = styled.span`
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM};
`;

export const AlreadySelectedSourcesWrapper = styled.div`
    margin-top: ${DesignConstants.SPACES.S};
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.S};
`;
