export enum Exercises {
    STANDARD = "STANDARD", // table exercise / 'tabelarische Abfrage'
    CONNECT_WORDS = "CONNECT_WORDS", // connect words / 'Wörter verbinden'
    FILL_GAP = "FILL_GAP", // fill gap / 'Lückensätze'
    WRITE_SENTENCE = "WRITE_SENTENCE", // form sentences / 'Sätze bilden'
    CREATE_TEXT = "CREATE_TEXT", // text production / 'Textproduktion'
    FREE_TEXT = "FREE_TEXT", // individual text fields / 'individuelles Textfeld'
    VERBTRAINING = "VERBTRAINING", // verb training / 'Verb training'
}

export const ExerciseTypes = new Map<Exercises, string>([
    [Exercises.STANDARD, "standard"],
    [Exercises.CONNECT_WORDS, "verbinden"],
    [Exercises.FILL_GAP, "fillInTheGap"],
    [Exercises.WRITE_SENTENCE, "writeSentence"],
    [Exercises.CREATE_TEXT, "jumbledWords"],
    [Exercises.FREE_TEXT, "textOnly"],
    [Exercises.VERBTRAINING, "verbTraining"],
]);

export const ExercisePrefixes = new Map<Exercises, string>([
    [Exercises.STANDARD, "std"],
    [Exercises.CONNECT_WORDS, "con"],
    [Exercises.FILL_GAP, "fig"],
    [Exercises.WRITE_SENTENCE, "wrs"],
    [Exercises.CREATE_TEXT, "juw"],
    [Exercises.FREE_TEXT, "txt"],
    [Exercises.VERBTRAINING, "vtr"],
]);

export const ExercisesWithoutPageWrapInPDF: Array<string> = [
    ExerciseTypes.get(Exercises.CONNECT_WORDS) ?? "",
    ExerciseTypes.get(Exercises.FILL_GAP) ?? "",
    ExerciseTypes.get(Exercises.CREATE_TEXT) ?? "",
];

export const ExercisesWithDirection: Array<string> = [
    ExerciseTypes.get(Exercises.STANDARD) ?? "",
    ExerciseTypes.get(Exercises.FILL_GAP) ?? "",
    ExerciseTypes.get(Exercises.WRITE_SENTENCE) ?? "",
    ExerciseTypes.get(Exercises.CREATE_TEXT) ?? "",
];

export const activeExerciseTypes = Object.values(Exercises).filter(
    (value) => Exercises[value] !== Exercises.VERBTRAINING
);
