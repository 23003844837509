import styled from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import ArrowButton from "../../../basic/arrowButton/ArrowButton";
import DesignConstants from "../../../constants/DesignConstants";

// shared

export const ExerciseName = styled.p`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

// SelectExerciseType

export const GeneralWrapper = styled.div`
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.S} 0 ${DesignConstants.SPACES.S};
`;

export const CreateSuggestionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.S};
    margin-bottom: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: block;
    }
`;

export const CreateSuggestionInfoText = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.L};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.XS};
    }
`;

export const BlankButtonNoBorder = styled(StyledButtonWithIcon)`
    min-width: fit-content;
    border: none !important;
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM} !important;
`;

export const SelectExerciseTypeWrapper = styled.div`
    width: 100%;
    margin: 0 0 ${DesignConstants.SPACES.S} 0;
    padding: 0 ${DesignConstants.SPACES.L};
`;

export const DropDownOuterContainer = styled.div`
    font-size: ${DesignConstants.FONT_SIZES.L};
    padding: ${DesignConstants.SPACES.XS};
    margin: 0;
    align-items: center;
    justify-content: space-between;
    border: ${DesignConstants.BORDERS.THIN_GREY};
    min-height: 45px;
    background: white;
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    min-width: 180px;
    cursor: pointer;
    user-select: none;
    text-transform: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: ${DesignConstants.SPACES.S};
`;

export const DropDownInnerContainer = styled.div`
    user-select: none;
    scroll-margin-top: 85px;
    flex-direction: row;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 ${DesignConstants.SPACES.XS} 0 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        scroll-margin-top: 70px;
    }
`;

export const CreateManuallyAndIconWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};

    &.infoOpen {
        > p {
            color: ${DesignConstants.COLORS.P6_ORANGE};
        }
    }
`;

export const IconWrapper = styled.div`
    width: ${DesignConstants.SPACES.L};
    height: ${DesignConstants.SPACES.M};
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const StyledIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;

export const OpenClosedWrapper = styled.div`
    cursor: pointer;
`;

export const StyledArrowButton = styled(ArrowButton)`
    padding: 0 !important;\
`;

export const NoExercisesWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${DesignConstants.SPACES.XXL} 0;
`;

export const NoExercisesText = styled.p``;

export const BottomScrollDiv = styled.div``;

// SelectExerciseTypeItem

export const ExerciseWrapper = styled.div`
    margin-bottom: ${DesignConstants.SPACES.S};
    background: white;
    border: ${DesignConstants.BORDERS.THIN_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
`;

export const ExerciseHeaderWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    padding: ${DesignConstants.SPACES.M} ${DesignConstants.SPACES.S};
    opacity: ${(props) => (props.disabled ? DesignConstants.OPACITY.HALF : 1)};

    &.infoOpen {
        padding: ${DesignConstants.SPACES.S};

        > p {
            color: ${DesignConstants.COLORS.P6_ORANGE};
        }
    }
`;

export const ExerciseNameWrapper = styled.div``;

export const ExerciseInfo = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.S};
`;

export const ExerciseQuestionMarkWrapper = styled.div<{ hidden?: boolean }>`
    display: ${(props) => (props.hidden ? "none" : "flex")};
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    padding: ${DesignConstants.SPACES.XXS};
`;

export const StyledQuestionMarkIcon = styled.span<{ color?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${DesignConstants.FONT_SIZES.S};
    color: ${(props) => props.color ?? DesignConstants.COLORS.DARK_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    width: ${DesignConstants.FONT_SIZES.L};
    height: ${DesignConstants.FONT_SIZES.L};
    border: ${DesignConstants.BORDERS.THIN} ${(props) => props.color ?? DesignConstants.COLORS.MID_GREY};
`;

export const ExerciseDescriptionWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex: 1;
    min-width: 250px;
    max-width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    align-self: center;
    padding-right: ${DesignConstants.SPACES.S};
    padding-bottom: ${DesignConstants.SPACES.S};

    opacity: ${(props) => (props.disabled ? DesignConstants.OPACITY.HALF : 1)};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`;

export const ExerciseDescriptionTextWrapper = styled.div`
    line-height: 1.4;
    padding-right: ${DesignConstants.SPACES.S};
    padding-left: ${DesignConstants.SPACES.S};
    flex: 1;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        padding: 0;
        margin: 0 0 ${DesignConstants.SPACES.S};
    }
`;

export const ExerciseDescriptionImageWrapper = styled.div`
    flex: 1;
    padding: ${DesignConstants.SPACES.XS};
    border: ${DesignConstants.BORDERS.THIN_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        max-width: 100%;
        margin-bottom: ${DesignConstants.SPACES.S};
    }
`;

export const ExerciseDescriptionImage = styled.img`
    max-width: 100%;
`;
