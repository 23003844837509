import React, { useEffect, useState } from "react";
import { useT } from "@transifex/react";
import { shuffle } from "lodash";
import { SidebarSelectedWord, TestDetailsWord, VerbtrainingSelectedWord } from "p6m-p6u";
import { useTestCreationContext } from "../../../../../context/TestCreationContext";
import { ExerciseDirections } from "../../../../../enums/directions";
import { translateWordFromModelToQuestion } from "../../../../../helpers/TestCreation";
import SelectExerciseVocabularyRow from "./selectExerciseVocabularyRow/SelectExerciseVocabularyRow";
import { animate } from "framer-motion";
import { ExercisesWithDirection } from "../../../../../enums/exercises";

import {
    ListingCtasRow,
    MainContentWrapper,
    RandomCardNumberSelectorWrapper,
    RandomSelectionAndDeleteWrapper,
    RandomSelectionWrapper,
    SelectAllCardsText,
    SelectAllCardsWrapper,
    StyledAddIcon,
    StyledExerciseInputField,
    StyledRandomSelectionButton,
} from "./StyledComponents";

export interface SelectExerciseVocabularyContentProps {
    availableVocabulary: SidebarSelectedWord[];
    disabled?: boolean;
    selectAllDisabled?: boolean;
    isLimitedExercise: boolean;
    currentAmountOfWords: number;
}

const defaultRandom = 5;

const SelectExerciseVocabularyContent: React.FC<SelectExerciseVocabularyContentProps> = (props) => {
    const { vocabularyDrawerContent, currentExerciseModel, setCurrentExerciseModel, testContent, setIsTestSynced } =
        useTestCreationContext();

    const t = useT();
    const t_randomSelection = t("Random selection", { _tags: "CreateTest,SelectExerciseVocabulary" });
    const t_selectAll = t("Select all", { _tags: "CreateTest,SelectExerciseVocabulary" });

    const [queue, setQueue] = useState<string[]>([]);
    const [processQueue, setProcessQueue] = useState(false);

    const remainingCardsAmount = props.isLimitedExercise
        ? Math.min(5 - (props.currentAmountOfWords + queue.length), props.availableVocabulary.length - queue.length)
        : props.availableVocabulary.length - queue.length;
    const defaultRandomCardsInputVal = Math.min(remainingCardsAmount, defaultRandom);

    const [randomCardsInputVal, setRandomCardsInputVal] = useState(defaultRandomCardsInputVal);

    useEffect(() => {
        if (randomCardsInputVal > remainingCardsAmount) {
            setRandomCardsInputVal(remainingCardsAmount);
        }
    }, [randomCardsInputVal, remainingCardsAmount]);

    useEffect(() => {
        if (processQueue && queue.length !== 0) {
            addWordsToExercise([queue[0]]);

            const filteredQueue = queue.filter((wordId) => wordId !== queue[0]);
            setQueue(filteredQueue);
            setProcessQueue(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processQueue]);

    function addWordsToExercise(wordIds: string[]) {
        if (!wordIds || wordIds.length < 1) return;
        const exercise = { ...currentExerciseModel };
        const newExerciseWords: Array<TestDetailsWord | VerbtrainingSelectedWord> = [];

        wordIds.forEach((w) => {
            if (vocabularyDrawerContent[w] && vocabularyDrawerContent[w].wordContent) {
                newExerciseWords.push(translateWordFromModelToQuestion(vocabularyDrawerContent[w].wordContent!));
            }
        });

        // set correct direction for each word for all exercises with direction
        if (ExercisesWithDirection.includes(exercise.questionMode ?? "")) {
            newExerciseWords.map((word) => {
                if ("id" in word) {
                    if (currentExerciseModel.direction === ExerciseDirections.RANDOM) {
                        const randomDirection = Math.random();
                        word.isQuestionShown = randomDirection <= 0.5;
                    } else {
                        word.isQuestionShown =
                            currentExerciseModel.direction === undefined ||
                            currentExerciseModel.direction === ExerciseDirections.QUESTION_TO_ANSWER;
                    }
                }
                return word;
            });
        }

        exercise.wordsInProgress = [...(exercise.wordsInProgress ? exercise.wordsInProgress : []), ...newExerciseWords];

        setCurrentExerciseModel(exercise);
        setIsTestSynced(false);
    }

    function prepareWordsToAdd(allOrRandom: "ALL" | "RANDOM") {
        const availableWords = props.availableVocabulary.filter((word) => !queue.includes(word.wordContent?.id || ""));
        const cardsToAdd =
            allOrRandom === "ALL" ? availableWords : shuffle(availableWords).slice(0, randomCardsInputVal);
        const ids = cardsToAdd.map((w) => w.wordContent?.id || "");
        addWordsToExercise(ids);
    }

    async function addToQueue(rowId: string, wordId: string) {
        if (remainingCardsAmount <= 0) return;

        setQueue((prevQueue: string[]) => [...prevQueue, wordId ?? ""]);

        const selectedRow = document.querySelector(`#${rowId}`);
        if (selectedRow) {
            await animate(selectedRow, { y: -152, opacity: 0 }, { duration: 0.35, ease: "easeIn" });
            setProcessQueue(true);
        }
    }

    const renderAllWordsSelector = () => (
        <SelectAllCardsWrapper
            className={props.disabled || props.selectAllDisabled ? "disabled" : ""}
            onClick={() => {
                if (!props.disabled && !props.selectAllDisabled) prepareWordsToAdd("ALL");
            }}
        >
            <StyledAddIcon name={"plus"} />
            <SelectAllCardsText>{t_selectAll}</SelectAllCardsText>
        </SelectAllCardsWrapper>
    );

    const renderRandomSelector = () => (
        <RandomSelectionAndDeleteWrapper>
            <RandomSelectionWrapper>
                <RandomCardNumberSelectorWrapper>
                    <StyledExerciseInputField
                        type={"number"}
                        max={remainingCardsAmount}
                        min={0}
                        value={randomCardsInputVal}
                        onChange={(e) => {
                            setRandomCardsInputVal(Math.min(remainingCardsAmount, Number(e.target.value)));
                        }}
                        className={remainingCardsAmount <= 0 || props.disabled ? "disabled" : ""}
                    />
                </RandomCardNumberSelectorWrapper>
                <StyledRandomSelectionButton
                    onClick={() => prepareWordsToAdd("RANDOM")}
                    arrowDirection={"NONE"}
                    buttonStyle={"BLANK"}
                    textStyle={"NO_TRANSFORMATION"}
                    disabled={remainingCardsAmount <= 0 || props.disabled}
                >
                    {t_randomSelection}
                </StyledRandomSelectionButton>
            </RandomSelectionWrapper>
        </RandomSelectionAndDeleteWrapper>
    );

    const renderVocabularyRows = () =>
        props.availableVocabulary.map((v) => {
            if (!v.wordContent || !v.wordContent.id) {
                return false;
            }

            const rowId = `row_${v.wordContent.id}`;
            const queueContainsWord = queue.includes(v.wordContent?.id || "");
            const usedInExercisesNumbers: number[] = [];
            v.exerciseIds?.forEach((id) => {
                const testContentIndex = testContent.findIndex(
                    (tc) => tc.exerciseId === id && tc.exerciseId !== currentExerciseModel.exerciseId
                );
                if (testContentIndex > -1) {
                    usedInExercisesNumbers.push(testContentIndex);
                }
            });

            return (
                <SelectExerciseVocabularyRow
                    key={"list_" + v.wordContent?.id}
                    word={v}
                    addToQueue={() =>
                        !queueContainsWord && addToQueue(rowId, v.wordContent?.id || "")
                    }
                    usedInExercises={usedInExercisesNumbers ?? []}
                    disabled={props.disabled || remainingCardsAmount <= 0}
                    rowId={rowId}
                    isRowAnimating={queueContainsWord}
                />
            );
        });

    return (
        <MainContentWrapper>
            <ListingCtasRow>
                {renderAllWordsSelector()}
                {renderRandomSelector()}
            </ListingCtasRow>
            {renderVocabularyRows()}
        </MainContentWrapper>
    );
};

export default SelectExerciseVocabularyContent;
